/* eslint-disable react/button-has-type */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { useHistory } from 'react-router-dom';
import {
  FieldArray, Form, Formik, Field, FieldProps,
} from 'formik';
import {
  Box, Modal, Typography, Checkbox, Link, FormControlLabel, MenuItem, TextField, makeStyles,
  Select, FormControl, InputLabel, Button,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import MainPageLayout from '../../../../components/MainPageLayout/MainPageLayout';
import { config } from '../../../../core/_consts/config';
import LoaderComponent from '../../../../components/Loader/Loader';
import ContinueButton from '../../../../components/Buttons/ContinueButton';
import { ROUTES } from '../../../../core/_consts/routes';

import TextInput from '../../../../components/TextInput/TextInput';
import 'react-toastify/dist/ReactToastify.css';
import './InvoiceFormInput.styles.scss';
import styles from '../../../../components/Headers/HeaderLeasing/Header.module.scss';
import Logo from '../../../../assets/images/leasing/logo.svg';

interface IInvoice {
  invoiceId: string,
  invoiceNumber: string,
  amount: string,
  fee: number,
  currency: string,
  status: string,
  link: string,
  expires: Date | null,
  qrCode: string,
}

const invoice: IInvoice = {
  invoiceId: '',
  invoiceNumber: '',
  amount: '',
  fee: 0,
  currency: '',
  status: '',
  link: '',
  expires: null,
  qrCode: '',
};

interface IFields {
  formId: string,
  fieldId: string,
  numberField: number | null,
  codeNameField: string,
  type: string,
  regExpField: string | null,
  isEnable: string,
  onlyRead: string,
  defaultValue: any,
  invoiceNameField: string | null,
  concatenationFields: string | null,
  concatenationFieldsTo: string | null,
  createdAt: Date | null,
  updatedAt: Date | null,
  deletedAt: Date | null,
}

interface IData {
  id: number,
  formId: string,
  clientId: number,
  status: string,
  isDelete: string,
  assetLogo: string | null,
  createdAt: Date | null,
  updatedAt: Date | null,
  deletedAt: Date | null,
  redirectPay: string,
  fields: IFields[],
}

const Fields:IData = {
  id: 0,
  formId: '',
  clientId: 0,
  status: '',
  isDelete: '',
  assetLogo: '',
  createdAt: null,
  updatedAt: null,
  deletedAt: null,
  redirectPay: '',
  fields: [{
    formId: '',
    fieldId: '',
    numberField: null,
    codeNameField: '',
    type: '',
    regExpField: '',
    isEnable: '',
    onlyRead: '',
    defaultValue: {},
    invoiceNameField: '',
    concatenationFields: '',
    concatenationFieldsTo: '',
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  }],
};
const uuid = uuidv4();

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  // eslint-disable-next-line no-shadow
  return () => setValue((value: any) => value + 1); // update the state to force render
}

const InvoiceFromInput = () => {
  const setLanguage = (language: any): string => {
    // eslint-disable-next-line default-case
    switch (language) {
      case 'US':
        return 'US';
      case 'en-GB':
        return 'US';
      case 'en-US':
        return 'US';
      default:
        return 'UA';
    }
  };

  const { language } = navigator;
  console.log(language);

  const currentLanguage = setLanguage(language);
  console.log(currentLanguage);
  const history = useHistory();
  const [data, setData] = useState<IData>(Object);
  const [fields, setFeilds] = useState<IFields[]>([]);
  const [merchantName, setMerchantName] = useState({
    UA: '',
    EN: '',
  });
  const [session, setSession] = useState();
  const [invoiceData, setInvoiceData] = useState<IInvoice>();
  const [amount, setAmount] = useState(0);
  const [clientName, setClientName] = useState('');
  const [currentValues, setValues] = useState();
  const [enumAmount, setEnumAmount] = useState([]);
  const [errorAmount, setErrorAmount] = useState<boolean | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openClient, setClientOpen] = React.useState(false);
  const handleClientOpen = () => setClientOpen(true);
  const handleClientClose = () => setClientOpen(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isStatus, setIsStatus] = useState(false);
  const [checked, setChecked] = useState(true);
  const [selected, setSelected] = useState(currentLanguage);
  const [button, setButton] = useState({
    UA: '',
    EN: '',
  });

  const useStyles = makeStyles({
    formControl: {
      minWidth: '283px',
      marginBottom: '16px',
      border: 'none',
      appearance: 'none',
      flexDirection: 'column',
      boxSizing: 'border-box',
      fontFamily: 'Montserrat',
      color: '#828282',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '22px',

      '& input': {
        height: 48,
        borderRadius: 4,
        boxSizing: 'border-box',
        background: '#EFEFEF',
        textAlign: 'center',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&:hover': {
        boxShadow: '0px 10px 10px 0px rgb(0 0 0 / 10%)',
      },
    },
  });

  const classes = useStyles();

  const [commentText, setCommentText] = useState({
    UA: '',
    EN: '',
  });

  const [externalId, setExternalId] = useState({
    UA: '',
    EN: '',
  });
  const params = window.location.search.split('=');
  const formId = params[1].split('&')[0];

  const getFields = async (idForm:string) => {
    const url = config.url.GET_FIELD_URL;

    try {
      const response = await fetch(`${url}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'origin': ''
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify({ formId: idForm }),
      });

      const res = await response.json();

      const { data: AllData } = res;

      if (res?.error) {
        history.push(ROUTES.CANCELED);
      }
      setData(AllData);

      if (res.data.assetBackground !== null) {
        // eslint-disable-next-line no-multi-assign,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-multi-assign
        document.querySelector('body').style.backgroundImage = `url('data:image/png;base64,${res.data.assetBackground}')`;
      }

      setFeilds(AllData.fields);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const sessionGet = async (id: string) => {
    const url = config.url.SESSION_GET;

    const sessionData = {
      deviceFP: id,
      returnSession: 'YES',
    };

    try {
      const response = await fetch(`${url}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'origin': ''
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(sessionData),
      });

      const res = await response.json();

      const { data: sessionRes } = res;
      setSession(sessionRes.sessionId);
    } catch (error) {
      history.push(ROUTES.CANCELED);
    }
  };

  function getValues(dataValues: IFields[]): any {
    const values: any = {};
    dataValues?.forEach((item: any): void => {
      if (item.defaultValue !== null) {
        values[item.codeNameField] = item?.defaultValue.UA ?? item?.defaultValue.value;
      }
    });

    return values;
  }

  function getValuesInEnglish(dataValues: IFields[]): any {
    const values: any = {};
    dataValues?.forEach((item: any): void => {
      if (item.defaultValue !== null) {
        values[item.codeNameField] = item?.defaultValue.EN ?? item?.defaultValue.value;
      }
    });

    return values;
  }

  const postData = useCallback(async (url = '', resData: any) => {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'origin': ''
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(resData),
    });
    return response;
  }, []);

  async function sendData(datVal: any, flag?: any, value?: any) {
    console.log(datVal, 'datVal');
    const local = selected === 'UA' ? 'UK' : 'EN';
    const formData: any = {
      deviceFP: uuid,
      sessionId: session,
      clientId: data.clientId,
      merchantId: datVal.merchantId,
      externalId: datVal.externalId,
      clientEmail: datVal.clientEmail,
      clientPhone: datVal.clientPhone,
      description: datVal.description,
      returnUrl: datVal.returnUrl,
      autoConvert: 'no',
      currency: 'UAH',
      expireType: 'minutes',
      expiresInMinutes: 45,
      feeCalculationType: 'INNER',
      defaultLocale: local,
      time: 45,
      withQR: 'YES',
    };
    console.log(datVal, 'datVal');
    console.log(amount, 'amount');
    console.log(clientName, 'clientName');
    // eslint-disable-next-line no-prototype-builtins
    if (datVal.hasOwnProperty('clientName')) {
      console.log(clientName);
      formData.clientName = datVal.clientName;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (datVal.hasOwnProperty('amount')) {
      console.log(clientName);
      formData.amount = datVal.amount;
    } else if (value) {
      formData.amount = value.toString();
    } else {
      formData.amount = amount.toString();
    }

    if (flag) {
      formData.clientName = clientName;
    }
    console.log('SEND==================');
    const url = `${config.url.CREATE_INVOICE}`;
    const response = await postData(url, formData);

    const res = await response.json();

    if (res?.error) {
      history.push(ROUTES.CANCELED);
    }

    const { data: invoiceCurrent } = res;

    setInvoiceData(invoiceCurrent);
    setIsLoadingModal(false);
  }
  const style = {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '1rem',
  };

  // eslint-disable-next-line max-len
  const handleSubmit = useCallback(async (values: any, resetForm: () => void, flag?: boolean) => {
    setIsLoadingModal(true);
    await sendData(values, flag);
    resetForm();
  }, [sendData]);

  useEffect(() => {
    if (data.redirectPay === 'YES') {
      window.location.href = invoiceData?.link ? invoiceData.link : '';
    }
  }, [invoiceData]);

  const checkStatus = async () => {
    const formData = {
      deviceFP: uuid,
      sessionId: session,
      clientId: data.clientId,
      invoiceId: invoiceData?.invoiceId,
    };

    const url = config.url.CHECK_STATUS;

    const responce = await postData(url, formData);

    const res = await responce.json();

    const { data: statusData } = res;
    setStatus(statusData.status);
    if (res?.error) {
      history.push(ROUTES.CANCELED);
    }
  };

  const notify = (message: string) => {
    switch (message) {
      case 'OPEN':
        toast.info(message, { position: toast.POSITION.TOP_CENTER });
        break;
      case 'PAID':
        toast.success(message, { position: toast.POSITION.TOP_CENTER });
        break;
      default:
        toast.info(message, { position: toast.POSITION.TOP_CENTER });
        break;
    }
  };

  const getMerchantName = () => {
    fields.map((i) => (i.codeNameField === 'merchantName' ? setMerchantName(i.defaultValue) : null));
  };
  const getEnumAmount = () => {
    fields.map((i) => (i.codeNameField === 'enumAmount' ? setEnumAmount(i.defaultValue.value) : null));
  };
  const getCommentText = () => {
    fields.map((i) => (i.codeNameField === 'commentText' ? setCommentText(i.defaultValue) : null));
  };

  useEffect(() => { getMerchantName(); }, [getMerchantName]);
  useEffect(() => { getEnumAmount(); }, [getEnumAmount]);
  useEffect(() => { getCommentText(); }, [getCommentText]);

  useEffect(() => {
    if (formId) {
      setIsLoading(true);
      getFields(formId);
    }
  }, [formId]);

  useEffect(() => {
    if (uuid) {
      sessionGet(uuid);
    }
  }, []);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  // const handleChangeSelect = (event: any) => {
  //   setAmount(event.target.value);
  // };

  // eslint-disable-next-line consistent-return
  const translateLabel = (name:any) => {
    // eslint-disable-next-line default-case
    switch (name) {
      case 'clientName':
        return 'ПІБ платника';
      case 'clientEmail':
        return 'Пошта';
      case 'description':
        return 'Призначення платежу';
      case 'externalId':
        return 'Номер замовлення';
      case 'clientPhone':
        return 'Номер телефону';
      case 'returnUrl':
        return 'Посилання';
      case 'amount':
        return 'Сума у гривні';
      case 'equal':
        return 'Еквівалент у USD та EUR';
      case 'enumAmount':
        return 'Вибір суми';
      default:
        return null;
    }
  };
  const translateLabelInEnglish = (name:any) => {
    // eslint-disable-next-line default-case
    switch (name) {
      case 'clientName':
        return 'Payer\'s name';
      case 'clientEmail':
        return 'Email';
      case 'description':
        return 'Purpose of payment';
      case 'externalId':
        return 'Order number';
      case 'clientPhone':
        return 'Phone number';
      case 'returnUrl':
        return 'Link';
      case 'amount':
        return 'Payment in Ukrainian hryvnia';
      case 'equal':
        return 'Equivalent in USD and EUR';
      case 'enumAmount':
        return 'Amount selection';
      default:
        return null;
    }
  };

  return (
    <MainPageLayout>
      <div className="form-header">
        <div className="select">
          <ReactFlagsSelect
            selected={selected}
            customLabels={{ US: 'EN', UA: 'UA' }}
            placeholder="Select Language"
            countries={['US', 'UA']}
            fullWidth={false}
            alignOptionsToRight
            onSelect={(code) => setSelected(code)}
          />
        </div>
        {data.assetLogo ? <img style={{ width: '300px', height: '300px' }} src={`data:image/png;base64,${data.assetLogo}`} alt="Logo" /> : ''}
        <br />
        <span className="form-header__title">{selected === 'UA' ? merchantName.UA : merchantName.EN}</span>
      </div>
      <div className="main__children">
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            <div className={isLoading ? 'form form_disable' : 'form'}>
              <Formik
                initialValues={selected === 'UA' ? getValues(fields) : getValuesInEnglish(fields)}
                onSubmit={(values, { resetForm }) => {
                  // eslint-disable-next-line no-debugger
                  // debugger;
                  console.log(values.clientName.length);
                  // eslint-disable-next-line no-prototype-builtins
                  if (!values.hasOwnProperty('clientName')) {
                    setValues(values);
                    handleClientOpen();
                    // eslint-disable-next-line no-prototype-builtins,no-mixed-operators
                  } else if (values.hasOwnProperty('clientName') && values.clientName === '' || values.clientName.length < 3) {
                    setValues(values);
                    notify(selected === 'UA' ? 'Довжина повинна бути більше трьох символів' : 'The length should be more than three characters');
                    // eslint-disable-next-line no-prototype-builtins,no-mixed-operators
                  } else if (!values.hasOwnProperty('amount') || values.amount === '') {
                    setValues(values);
                    // notify('Веддіть Сумму');
                    // eslint-disable-next-line no-prototype-builtins,no-mixed-operators
                  } else if (+values.amount >= 30000) {
                    setValues(values);
                    handleClientOpen();
                  } else {
                    setValues(values);
                    if (values.amount !== '') {
                      handleSubmit(values, resetForm, true);
                    }
                    setErrorAmount(true);
                  }
                }}
                enableReinitialize
              >
                {({
                  isSubmitting, values, setFieldValue, resetForm,
                  // eslint-disable-next-line no-shadow
                  handleChange, errors, touched,
                }) => (
                  <Form className="myForm">
                    <FieldArray
                      name="data"
                      render={(formikProps) => (
                        <div>
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {fields?.map((item: any) => (item.isEnable === 'YES' && item.type === 'INPUT' ? (
                            <div key={item.fieldId}>
                              <Field name={item.codeNameField}>
                                {/* eslint-disable-next-line no-nested-ternary */}
                                {item.type === 'INPUT'
                                  ? (
                                    ({ field }: FieldProps) => (
                                      <TextInput
                                        labelName={selected === 'UA' ? translateLabel(item.codeNameField) : translateLabelInEnglish(item.codeNameField)}
                                        placeholder=""
                                        disabled={item.onlyRead === 'YES'}
                                        {...field}
                                        onChange={item.codeNameField === 'amount' ? (e: any): void => {
                                          handleChange(e);
                                          const a = e.currentTarget.value;
                                          const USD = (a / 41).toFixed(2);
                                          const EUR = (a / 43).toFixed(2);
                                          setFieldValue('amount', a);
                                          setFieldValue('equal', `${USD} USD / ${EUR} EUR`);
                                        } : formikProps.form.handleChange}
                                      />
                                    )
                                  ) : null}

                              </Field>
                            </div>
                            // eslint-disable-next-line no-shadow
                          ) : item.type === 'SELECT' && item.isEnable === 'YES'
                            ? (
                              <div>
                                <InputLabel
                                  id="demo-simple-select-helper-label"
                                  key="label"
                                  style={{
                                    textAlign: 'center',
                                    marginBottom: '6px !important',
                                    padding: '0 20px',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    color: '#FFFFFF',
                                    fontFamily: 'Montserrat, sans-',
                                  }}
                                >
                                  {selected === 'UA' ? translateLabel(item.codeNameField) : translateLabelInEnglish(item.codeNameField)}
                                </InputLabel>
                                {
                                  // eslint-disable-next-line no-shadow
                                  enumAmount.map((value) => (
                                    <button
                                      className="buttonS buttonAmount"
                                      key={value}
                                      onClick={() => {
                                        // eslint-disable-next-line no-debugger
                                        // debugger;
                                        // eslint-disable-next-line no-prototype-builtins
                                        if (values.hasOwnProperty('clientName') && values.clientName.length < 3) {
                                          setValues(values);
                                          notify(selected === 'UA' ? 'Довжина повинна бути більше трьох символів' : 'The length should be more than three characters');
                                        }
                                        // eslint-disable-next-line no-debugger
                                        // debugger;
                                        // eslint-disable-next-line no-prototype-builtins
                                        if ((values.hasOwnProperty('clientName') && values.clientName.length <= 3) || values.amount >= 30000) {
                                          console.log(value, 'AMOUNT');
                                          setAmount(value);
                                          setValues(values);
                                          const USD = (value / 41).toFixed(2);
                                          const EUR = (value / 43).toFixed(2);
                                          // eslint-disable-next-line max-len
                                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                          // @ts-ignore
                                          setFieldValue('amount', value.toString());
                                          setFieldValue('equal', `${USD} USD / ${EUR} EUR`);
                                          // eslint-disable-next-line no-prototype-builtins
                                          if (values.hasOwnProperty('clientName') && values.clientName.length >= 3) {
                                            sendData(values, false, value);
                                          }
                                          if (data.redirectPay === 'NO' && values.clientName.length >= 3) {
                                            handleOpen();
                                          }
                                          resetForm();
                                          // handleClientOpen();
                                          // eslint-disable-next-line no-prototype-builtins
                                        } else if (values.clientName?.length >= 3 || !values.hasOwnProperty('clientName')) {
                                          console.log(value);
                                          setAmount(value);
                                          const USD = (value / 41).toFixed(2);
                                          const EUR = (value / 43).toFixed(2);
                                          // eslint-disable-next-line max-len
                                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                          // @ts-ignore
                                          setFieldValue('amount', value.toString());
                                          setFieldValue('equal', `${USD} USD / ${EUR} EUR`);
                                          // eslint-disable-next-line no-prototype-builtins
                                          if (values.hasOwnProperty('clientName') && values.clientName.length >= 3) {
                                            sendData(values, false, value);
                                          }
                                          // eslint-disable-next-line no-prototype-builtins
                                          if (!values.hasOwnProperty('clientName')) {
                                            sendData(values, false, value);
                                          }
                                          if (data.redirectPay === 'NO') {
                                            handleOpen();
                                          }
                                          resetForm();
                                          // eslint-disable-next-line no-prototype-builtins
                                        } else if (!values.hasOwnProperty('clientName')) {
                                          console.log(value);
                                          setAmount(value);
                                          const USD = (value / 41).toFixed(2);
                                          const EUR = (value / 43).toFixed(2);
                                          // eslint-disable-next-line max-len
                                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                          // @ts-ignore
                                          setFieldValue('amount', value.toString());
                                          setFieldValue('equal', `${USD} USD / ${EUR} EUR`);
                                          // eslint-disable-next-line no-prototype-builtins
                                          if (values.hasOwnProperty('clientName') && values.clientName.length >= 3) {
                                            sendData(values, false, value);
                                          }
                                          if (data.redirectPay === 'NO') {
                                            handleOpen();
                                          }
                                          resetForm();
                                        }

                                        // eslint-disable-next-line no-prototype-builtins
                                        if (data.redirectPay === 'NO' && values.hasOwnProperty('amount') && values.amount !== '') {
                                          handleOpen();
                                          resetForm();
                                        }
                                      }}
                                      type="submit"
                                    >
                                      {value}
                                    </button>
                                  ))
                                }
                              </div>
                            )
                            : null))}
                        </div>
                      )}
                    />
                    <div className="form__button">
                      <ContinueButton
                        buttonText={selected === 'UA' ? getValues(fields).button : getValuesInEnglish(fields).button}
                        isDisabled={!checked}
                        onClick={() => {
                          // eslint-disable-next-line no-debugger
                          debugger;
                          if (values.amount === undefined) {
                            setValues(values);
                            notify('Веддить сумму');
                            handleClose();
                            // eslint-disable-next-line no-prototype-builtins
                          } else if ((!values.hasOwnProperty('clientName') || values.clientName?.length < 3) && +values.amount >= 30000) {
                            setValues(values);
                            // eslint-disable-next-line no-prototype-builtins
                            if (!values.hasOwnProperty('clientName')) {
                              handleClientOpen();
                            }
                          } else if (values.clientName?.length < 3 && +values.amount >= 30000) {
                            notify(selected === 'UA' ? 'Довжина повинна бути більше трьох символів' : 'The length should be more than three characters');
                            setValues(values);
                          } else {
                            setValues(values);
                            // eslint-disable-next-line no-prototype-builtins
                            if ((!values.hasOwnProperty('clientName') && +values.amount > 0) || (values.clientName.length >= 3 && +values.amount !== 0)) {
                              sendData(values);
                              resetForm();
                            }
                          }
                          // eslint-disable-next-line no-prototype-builtins,no-mixed-operators
                          if (data.redirectPay === 'NO' && (values.clientName?.length >= 3 || +values.amount !== 0)) {
                            // eslint-disable-next-line no-prototype-builtins
                            if (!values.hasOwnProperty('clientName')) {
                              if (+values.amount > 0) {
                                handleOpen();
                                resetForm();
                              }
                              // eslint-disable-next-line no-prototype-builtins
                            } else if (values.hasOwnProperty('clientName') && values.clientName !== '' && values.clientName?.length >= 3 && +values.amount > 0) {
                              handleOpen();
                              resetForm();
                            }
                          }
                        }}
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>

            </div>
            <div className="commenttextstyle">
              <span className="commenttextstyle">{ selected === 'UA' ? commentText.UA : commentText.EN }</span>
            </div>
          </>
        )}
      </div>
      <Modal
        open={open}
        onClose={() => { handleClose(); setIsStatus(false); handleClientClose(); }}
      >
        { isLoadingModal ? (
          <LoaderComponent />
        ) : (
          <Box sx={style}>
            <div>
              <img src={invoiceData?.qrCode} alt="" />
            </div>
            <div>
              <a href={invoiceData?.link} target="_blank" rel="noreferrer">{invoiceData?.link}</a>
            </div>
            {status && isStatus
              ? notify(status)
              : undefined}
            <div className="form__button">
              <ContinueButton buttonText="Статус" onClick={() => { checkStatus(); setIsStatus(true); setStatus(null); }} type="submit" />
            </div>
          </Box>
        )}
      </Modal>
      <Modal
        className="clientModal"
        open={openClient}
        onClose={() => { handleClientClose(); handleClose(); }}
      >
        { isLoadingModal ? (
          <LoaderComponent />
        ) : (
          <Box sx={style}>
            <div className="input-text-client">
              <div className="input-text-client__label">{selected === 'UA' ? translateLabel('clientName') : translateLabelInEnglish('clientName')}</div>
              <TextField
                type="text"
                variant="outlined"
                className={classes.formControl}
                onChange={(e) => {
                  setClientName(e.currentTarget.value);
                }}
              />
            </div>
            <div className="form__button">
              <ContinueButton
                buttonText={selected === 'UA' ? getValues(fields).button : getValuesInEnglish(fields).button}
                onClick={(e) => {
                  console.log(clientName, 'clientName');
                  if (clientName !== '' && clientName.length >= 3) {
                    console.log(currentValues, 'currentValues');
                    sendData(currentValues, true);
                    handleClientClose();
                  } else {
                    notify(selected === 'UA' ? 'Довжина повинна бути більше трьох символів' : 'The length should be more than three characters');
                  }
                  if (data.redirectPay === 'NO') {
                    handleOpen();
                  }
                }}
                type="submit"
              />
            </div>
          </Box>
        )}
      </Modal>
    </MainPageLayout>
  );
};

export default InvoiceFromInput;
