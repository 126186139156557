import React, { ReactElement } from 'react';

import styles from '../WCSPaymentCancel/WCSPaymentCancel.module.scss';
import { ReactComponent as Declined } from '../../../../assets/images/wcs/wcs-declined.svg';

const WCSGlobalError = (): ReactElement => (
  <div className={styles.payment__cancel}>
    <div className={styles.payment__cancel__title}>
      <span>Виникла системна помилка. </span>
      <span> Зверніться у технічну підтримку.</span>
    </div>
    <div className={styles.payment__cancel__icon}>
      <Declined />
    </div>
  </div>
);

export default WCSGlobalError;
