/* eslint-disable react/button-has-type */
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Formik, Form, Field, FieldProps,
} from 'formik';
// import {TextField, makeStyles} from '@material-ui/core';
import * as Yup from 'yup';

import LoaderComponent from '../../../../components/Loader/Loader';
import MainPageLayout from '../../../../components/MainPageLayout/MainPageLayout';
import ContinueButton from '../../../../components/Buttons/ContinueButton';
import { config } from '../../../../core/_consts/config';
import TextInput from '../../../../components/TextInput/TextInput';
import { ROUTES } from '../../../../core/_consts/routes';

import './FormInput.styles.scss';

const ValidationSchema = Yup.object().shape({
  contractNumber: Yup.string().required('Required'),
  fullName: Yup.string().required('Required'),
  phoneNumber: Yup.string().required(),
  amount: Yup.number().required(),
  identCode: Yup.number().required(),
});
interface ILeasingForm {
  contractNumber: string;
  fullName: string;
  phoneNumber: string;
  amount: string;
  orderNumber: string,
  contractId: string,
  accountServiceCallbackUrl: string
  identCode: string;
}

const initFormik: ILeasingForm = {
  contractNumber: '',
  fullName: '',
  phoneNumber: '',
  amount: '',
  orderNumber: '',
  contractId: '',
  accountServiceCallbackUrl: '',
  identCode: '',
};

interface IDisabledForm {
  contractNumber: boolean;
  fullName: boolean;
  phoneNumber: boolean;
  amount: boolean;
  identCode: boolean;
}

const initDisabledFields: IDisabledForm = {
  contractNumber: false,
  fullName: false,
  phoneNumber: false,
  amount: false,
  identCode: false,
};

const LeasingFormInput = () => {
  const history = useHistory();

  // const [check, setCheck] = useState(false);
  const [amount, setAmount] = useState(0);
  const [errorAmount, setErrorAmount] = useState<boolean | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [accountSystemData, setAccountSystemData] = useState(initFormik);
  const [disabledFields, setDisabledFields] = useState(initDisabledFields);

  const params = window.location.search.split('=');
  // console.info('params !!! ', window.location.search, params, params[params.length - 1]);
  const token = params[params.length - 1];

  const handleProperties = (obj: any) => {
    const check = [];
    const properties = [
      'callback_url',
      'cell_phone',
      'client_name',
      'contract_id',
      'contract_number',
      'inn',
      'order_number',
      'payment_date',
      'payment_sum',
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (const property of properties) {
      check.push(property in obj);
    }
    const values = Object.values(obj).filter((item) => !!item);
    return check.length === values.length;
  };

  // eslint-disable-next-line consistent-return,no-shadow
  const verifyAndGetInitialData = useCallback(async (token: string) => {
    const url = config.url.ACCOUNT_SYSTEM_CHECK_TOKEN_URL;

    try {
      const response = await fetch(`${url}?token=${token}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'origin': ''
        },
        redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify({ token }),
      });

      // console.info('verifyAndGetInitialData00000', await response)

      const res = await response.json();
      // console.info('verifyAndGetInitialData11111', res);

      const { data } = res;

      if (res?.error) {
        console.error(res?.errorData?.message);
        history.push(ROUTES.CANCELED);
      }

      if (!handleProperties(data)) {
        history.push(ROUTES.CANCELED);
        setIsLoading(false);
        return false;
      }
      setAccountSystemData((prevState) => {
        const newState = {
          ...prevState,
          contractNumber: data.contract_number,
          amount: data.payment_sum,
          orderNumber: data.order_number,
          contractId: data.contract_id,
          accountServiceCallbackUrl: data.callback_url,
          fullName: data.client_name,
          identCode: data.inn,
          phoneNumber: data.cell_phone,
        };

        return newState;
      });

      setDisabledFields((prevState) => {
        const newState = {
          ...prevState,
          contractNumber: true,
        };

        return newState;
      });
      setAmount(data.payment_sum);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      return null;
    }
  }, [history]);

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      verifyAndGetInitialData(token);
    }
  }, [token, verifyAndGetInitialData]);

  const postData = useCallback(async (url = '', data: any) => {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'origin': ''
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data),
    });
    return response;
  }, []);

  async function send(data: any) {
    // eslint-disable-next-line no-shadow
    const { amount } = data;
    const { contractNumber } = data;
    const { fullName } = data;
    const { phoneNumber } = data;
    const inn = data.identCode;

    if (!amount
        || !contractNumber
        || !fullName
        || !phoneNumber
        || !inn
    ) {
      console.error('All fields must be filled!!!');
      return;
    }

    const formData = {
      amount: `${amount}`,
      contractNumber: accountSystemData?.contractNumber || contractNumber,
      // Change for testing
      // orderNumber: `${Math.floor(Math.random()*1000)}`,
      orderNumber: accountSystemData?.orderNumber,
      contractId: accountSystemData?.contractId,
      // eslint-disable-next-line max-len
      accountServiceCallbackUrl: accountSystemData?.accountServiceCallbackUrl || config.url.ACCOUNT_SYSTEM_CALLBACK_URL,
      description: `${accountSystemData?.contractNumber || contractNumber}; ${
        data.fullName
      };${data.phoneNumber}; ${data.identCode}`,
    };

    const url = `${config.url.API_URL}/leasing-transport?token=${token}`;

    const res = await postData(url, formData);

    const response = await res.json();

    if (response.data?.error) {
      history.push(ROUTES.CANCELED, {
        token,
      });
    } else {
      window.location.href = response.data.url;
    }
  }

  const handleSubmit = useCallback(async (values: ILeasingForm, resetForm: () => void) => {
    console.log('handleSubmit!!!', values);
    setIsLoading(true);
    await send(values);
    resetForm();
  }, [send]);

  const clearErrorState = useCallback(() => {
    setErrorAmount(false);
  }, []);

  const showAmountErrors = useMemo(() => (errorAmount
    ? (<span className={`input-amount__error ${errorAmount ? 'visible' : ''}`}>{`Мінімум ${accountSystemData.amount} грн`}</span>)
    : <span className="empty-error-span" />), [errorAmount, accountSystemData]);

  return (
    <MainPageLayout>
      <div className="support-container">
        <span className="support-title">Гаряча лінія</span>
        <span className="support-number">+380670777111</span>
      </div>
      <div className="form-header">
        <span className="form-header__title">ACTINIA LEASING</span>
        <span className="form-header__description">Щомісячний платіж</span>
      </div>
      <div className="main__children">
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <div className={isLoading ? 'form form_disable' : 'form'}>
            <Formik
              initialValues={accountSystemData}
              onSubmit={(values, { resetForm }) => {
                if (Number(values.amount) < amount) {
                  setErrorAmount(true);
                } else {
                  handleSubmit(values, resetForm);
                }
              }}
              validationSchema={ValidationSchema}
            >
              {({ errors, touched }) => (
                <Form className="form">
                  <Field name="contractNumber" component="form" key={1}>
                    {({ field }: FieldProps) => (
                      <TextInput
                        labelName="Номер договору"
                        placeholder=""
                        disabled
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="fullName" component="form" key={2}>
                    {({ field }: FieldProps) => (
                      <TextInput
                        labelName="ПІБ"
                        placeholder=""
                        disabled
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="phoneNumber" component="form" key={3}>
                    {({ field }: FieldProps) => (
                      <TextInput
                        labelName="Номер телефону"
                        placeholder=""
                        disabled
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="identCode" component="form" key={5}>
                    {({ field }: FieldProps) => (
                      <TextInput
                        labelName="ІПН"
                        placeholder=""
                        disabled
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="amount" component="form" key={4}>
                    {({ field }: FieldProps) => (
                      <TextInput
                        labelName="Вкажіть бажану суму  (в грн, не менше вказаної)"
                        placeholder=""
                        error={
                                    errorAmount
                                    || (touched.amount && !!errors.amount)
                                  }
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...field}
                        onBlur={clearErrorState}
                      />
                    )}
                  </Field>
                  {showAmountErrors}
                  <div className="form__button">
                    <ContinueButton buttonText="Оплатити" type="submit" />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </MainPageLayout>
  );
};

export default LeasingFormInput;
