import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { cleanup } from '@testing-library/react';
import { config } from '../../../../core/_consts/config';
import { ROUTES } from '../../../../core/_consts/routes';
import LoaderComponent from '../../../../components/Loader/Loader';
import {
  globalErrorHanding,
  IWCSActivation,
  IWCSAttempt,
  IWCSCompensation,
  IWCSInfo,
  IWCSWait,
  wogInstallmentRouting,
} from '../../../../utils/routesProvider';
import { createState } from '../../../../utils/helpers';

const WCSMainPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [wcsState, setWcsState] = useState<
      | IWCSActivation
      | IWCSAttempt
      | IWCSInfo
      | IWCSCompensation
      | IWCSWait
      | any
    >();

  const [isLoaded, setIsLoaded] = useState(false);
  const locationCurrent = location.search;
  const token = locationCurrent.split('=')[1];
  console.log('token: ', token);
  sessionStorage.setItem('installmentToken', token);

  // eslint-disable-next-line no-shadow
  const verifyAndGateInitialData = useCallback(async (token: string) => {
    const url = config.url.WCS_CHECK_TOKEN;

    try {
      const response = await fetch(`${url}?token=${token}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          origin: '*actinia.tech',
          'Content-Type': 'application/json',
          Cookie: 'deviceFP=15914eaa3bd22928276cd5ddf4a7f1b13901758a',
        },
        redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify({ token }),
      });

      const res = await response.json();
      const data = res.data?.data;
      console.log('data: ', data);
      console.log('res: ', res);

      if (
        JSON.stringify(res).includes('error')
        || res.data?.httpStatus === 500
      ) {
        globalErrorHanding(res.data?.error, token, history);
      } else {
        const currentState = createState({
          ...data,
          action: res.data.action,
          token,
        });

        console.info('setWcsState=====', currentState);

        setWcsState((prevState: any) => {
          const newWcsState = {
            ...prevState,
            ...currentState,
          };

          return newWcsState;
        });
      }

      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(false);
    }
  }, [history]);

  useEffect(() => {
    setIsLoaded(false);
    verifyAndGateInitialData(token);
  }, [token, verifyAndGateInitialData]);

  useEffect(() => {
    if (wcsState) {
      wogInstallmentRouting(wcsState, history);
    }

    return () => {
      cleanup();
    };
  }, [history, wcsState]);

  return !isLoaded ? <LoaderComponent /> : null;
};

export default WCSMainPage;
