/*eslint-disable*/

export const ROUTES = {
  HOME: "/leasing",
  ACCEPTED: "/accepted",
  DECLINED: "/declined",
  CANCELED: "/canceled",
  LEASING_HOME: "/leasing",
  LEASING_ACCEPTED: "/leasing/accepted",
  LEASING_DECLINED: "/leasing/declined",
  LEASING_CANCELED: "/leasing/canceled",
  INVOICE_HOME: "/qrpos",

  wog: {
    installment: {
      CANCELED: "/wog/installment/canceled",
      ERROR: "/wog/installment/error",

      verification: {
        HOME: "/wog/installment",
        WITH_VERIFICATION: "/wog/installment/verification",
        WITHOUT_VERIFICATION: "/wog/installment/noverification",
        ACCEPTED_WITH_VERIFICATION: "/wog/installment/verification/accepted",
        ACCEPTED_WITHOUT_VERIFICATION:
          "/wog/installment/noverification/accepted",
        DECLINED: "/wog/installment/verification/declined",
      },

      repayment: {
        HOME: "/wog/installment/repayment",
        INFO: "/wog/installment/info/repayment",
        ACCEPTED: "/wog/installment/accepted/repayment",
        ACCEPTED_FULL: "/wog/installment/accepted-full/repayment",
        DECLINED: "/wog/installment/declined/repayment",
        CANCELED: "/wog/installment/canceled/repayment",
        ATTEMPT: "/wog/installment/attempt/repayment",
        HELP: "/wog/installment/help/repayment",
      },
    },
  },
};
