import React, { FC } from 'react';
import { ReactComponent as Currency } from '../../assets/images/wcs/ua.svg';
import { ReactComponent as Like } from '../../assets/images/wcs/like-outlined.svg';
import { ReactComponent as Calendar } from '../../assets/images/wcs/calendar.svg';

import styles from './WCSContent.module.scss';

interface IWCSContentProps {
  limit: number | null;
}

const WCSContent: FC<IWCSContentProps> = ({ limit }: IWCSContentProps) => (
  <div className={styles.content}>
    <div className={styles.content__title}>Заправляй зараз, а сплачуй потім</div>
    <ul className={styles.content__list}>
      <li className={styles.content__item}>
        <div className={styles.content__image}>
          <Currency />
        </div>
        <div className={styles.text}>
          <span className={styles.text__bigger}>
            {limit}
            <Currency className={styles.currency} />
          </span>
          <span className={styles.text__smaller}>Доступний ліміт</span>
        </div>
      </li>
      <li className={styles.content__item}>
        <div className={styles.content__image}>
          <Like />
        </div>
        <div className={styles.text}>
          <span className={styles.text__bigger}>0%</span>
          <span className={styles.text__smaller}>річних</span>
        </div>
      </li>
      <li className={styles.content__item}>
        <div className={styles.content__image}>
          <Calendar />
        </div>
        <div className={styles.text}>
          <div className={styles.text__bigger}>
            14
            <span>днів</span>
          </div>
          <span className={styles.text__smaller}>ТЕРМІН ПОГАШЕННЯ</span>
        </div>
      </li>
    </ul>
  </div>
);

export default WCSContent;
