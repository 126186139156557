import { Slider, withStyles } from '@material-ui/core';
import React, { FC } from 'react';

interface InputRangeProps {
  min: number;
  max: number;
  step: number;
  onChange?: (e: any, value: any) => void;
  value?: any;
}

const CustomSlider = withStyles({
  root: {
    width: '289px',
    color: '#02593D',
  },
  rail: {
    color: '#000000',
  },
  thumb: {
    height: '18px',
    width: '18px',
    borderRadius: 'unset !important',
    marginTop: '-8px',
  },
  valueLabel: {
    left: '-50%',
    transform: 'translateX(-50%)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#02593D',
      fontWeight: 'bold',
      fontSize: '12px',
    },
  },
})(Slider);

const InputRange: FC<InputRangeProps> = ({
  min,
  max,
  step,
  onChange,
  value,
}: InputRangeProps) => (
  <CustomSlider
    defaultValue={0}
    min={+min.toFixed(2)}
    max={+max.toFixed(2)}
    value={+value.toFixed(2)}
    step={+step.toFixed(2)}
    valueLabelDisplay="on"
    onChange={onChange}
  />
);

export default InputRange;
