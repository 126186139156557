import React from 'react';
import styles from './WCSButton.module.scss';

interface IWCSButton {
  data?: any;
  handleClick?: (data: any) => void;
  buttonText?: string;
  isDisabled?: boolean;
  token?: string;
  type: 'button' | 'submit' | 'reset' | undefined;
  id?: string;
}

const WCSButton: React.FC<IWCSButton> = ({
  buttonText,
  isDisabled,
  handleClick,
  type,
  id,
}: IWCSButton) => (
  <button
    id={id}
    /* eslint-disable-next-line react/button-has-type */
    type={type}
    disabled={isDisabled}
    className={styles.button}
    onClick={handleClick}
  >
    {buttonText}
  </button>
);

export default WCSButton;
