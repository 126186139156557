import { ROUTES } from '../core/_consts/routes';

export interface IWCSInstallment {
  action: string;
  success: string;
  token: string;
  phone: string;
  pride_id: string;
  wog_id: string;
}

export interface IWCSActivation extends IWCSInstallment {
  activation_type: string;
  activation_type_id: number | null;
  limit_id: number | null;
  limit_max: number | null;
  limit_min: number | null;
}

export interface IWCSWait extends IWCSInstallment {
  activation_type: string;
  activation_type_id: number | null;
  activation__status_id: number | null;
  activation_status: string;
  activation_expire: string;
}

export interface IWCSInfo extends IWCSActivation, IWCSInstallment {}

export interface IWCSCompensation extends IWCSInstallment {
  owing_sum: number;
  owing_compensated: number;
  owing_sum_min: number;
  owing_created: string;
  owing_compensated_start: string;
  owing_order_id: string;
  fname: string;
  lname: string;
  callback_url: string;
}

export interface IWCSAttempt extends IWCSCompensation, IWCSInstallment {}

function checkValidationError(error: any, token: string, history: any) {
  const errorString = JSON.stringify(error);
  if (errorString.includes('token')) {
    history.push(`${ROUTES.wog.installment.CANCELED}/${Date.now()}`);
  } else if (errorString.includes('inn') || errorString.includes('documents')) {
    history.push(
      `${ROUTES.wog.installment.verification.DECLINED}/${Date.now()}`,
      { token },
    );
  } else {
    history.push(`${ROUTES.wog.installment.ERROR}/${Date.now()}`);
  }
}

export const globalErrorHanding = (error: any, token: string, history: any) => {
  const { code } = error;
  if (code) {
    switch (code) {
      case 101:
        checkValidationError(error, token, history);
        break;
      default:
        history.push(`${ROUTES.wog.installment.ERROR}/${Date.now()}`);
        break;
    }
  } else {
    history.push(`${ROUTES.wog.installment.ERROR}/${Date.now()}`);
  }
};

function wogRouting(token: string, history: any) {
  if (!token) {
    history.push(`${ROUTES.wog.installment.CANCELED}/${Date.now()}`);
  }
}

function checkEmptyParameters(data: any) {
  const values = Object.values(data).filter((item) => !!item);
  return values.length === Object.keys(data).length;
}

function getRouting(action: string, parameters: any) {
  const storage = localStorage;
  const { activation_type_id, limit_min, limit_max } = parameters;
  const activation = parameters?.activation_type_id;

  switch (action) {
    case 'activation':
      storage.setItem('limitMax', limit_max.toFixed(2));
      storage.setItem('limitMin', limit_min.toFixed(2));

      if (checkEmptyParameters(parameters)) {
        return activation_type_id === 1
          ? `${
            ROUTES.wog.installment.verification.WITHOUT_VERIFICATION
          }/${Date.now()}`
          : `${
            ROUTES.wog.installment.verification.WITH_VERIFICATION
          }/${Date.now()}`;
      }
      return `${ROUTES.wog.installment.CANCELED}/${Date.now()}`;

    case 'compensation':
      return checkEmptyParameters(parameters)
        ? `${ROUTES.wog.installment.repayment.HOME}/${Date.now()}`
        : `${ROUTES.wog.installment.CANCELED}/${Date.now()}`;
    case 'attempt':
      return checkEmptyParameters(parameters)
        ? `${ROUTES.wog.installment.repayment.ATTEMPT}/${Date.now()}`
        : `${ROUTES.wog.installment.CANCELED}/${Date.now()}`;
    case 'wait':
      // eslint-disable-next-line no-nested-ternary
      return activation === 1
        ? `${
          ROUTES.wog.installment.verification.ACCEPTED_WITHOUT_VERIFICATION
        }/${Date.now()}`
        : activation === 2
          ? `${
            ROUTES.wog.installment.verification.ACCEPTED_WITH_VERIFICATION
          }/${Date.now()}`
          : `${ROUTES.wog.installment.verification.DECLINED}/${Date.now()}`;
    default:
      return null;
  }
}

export function wogInstallmentRouting(
  routingParameters:
    | IWCSActivation
    | IWCSAttempt
    | IWCSInfo
    | IWCSCompensation
    | IWCSWait,
  history: any,
) {
  sessionStorage.setItem(
    'routingParameters',
    JSON.stringify(routingParameters),
  );
  const { action } = routingParameters;
  let url;

  switch (action) {
    case 'info':
      url = `${ROUTES.wog.installment.repayment.INFO}/${Date.now()}`;
      break;
    case 'activation':
    case 'compensation':
    case 'attempt':
    case 'wait':
      url = getRouting(action, routingParameters);
      break;
    default:
      url = `${ROUTES.wog.installment.ERROR}/${Date.now()}`;
      break;
  }

  console.log('url, { routingParameters : ', url, routingParameters);

  history.push(url, { routingParameters });
}

function leasingRouting(locationQueryArray: string[], history: any) {
  let status = null;
  let token = locationQueryArray[1];

  if (locationQueryArray.length > 2) {
    // eslint-disable-next-line prefer-destructuring
    status = locationQueryArray[2];

    if (status === 'approve') {
      // eslint-disable-next-line prefer-destructuring
      token = locationQueryArray[1].split(';')[0];
      history.push(ROUTES.LEASING_ACCEPTED, {
        token,
      });
    }
    if (status === 'decline') {
      // eslint-disable-next-line prefer-destructuring
      token = locationQueryArray[1].split(';')[0];
      history.push(ROUTES.LEASING_DECLINED, {
        token,
      });
    }
    // history.push(ROUTES.HOME);
  }

  //   if (!token || (!status && token?.length > 12)) {
  //     history.push(ROUTES.CANCELED);
  //   }

  if (!token) {
    history.push(ROUTES.CANCELED);
  }
}

function qrposRouting(formId: string[], history:any) {
  let status = null;
  const formIdQuery = formId[1];

  if (formId.length > 2) {
    // eslint-disable-next-line prefer-destructuring
    status = formId[2];
  }
  if (status === 'approve') {
    history.push(`${ROUTES.INVOICE_HOME}${formId[0]}`, {
      formIdQuery,
    });
  }

  if (!formIdQuery) {
    history.push(ROUTES.CANCELED);
  }
}

export function distributeRoutes(location: Location, history: any) {
  const locationQueryArray = window.location.search.split('=');
  const formIdQuerry = window.location.search.split('=');
  const token = sessionStorage.getItem('installmentToken')
    ? (sessionStorage.getItem('installmentToken') as string)
    : locationQueryArray[1];
  const { pathname } = location;
  const currentPath = pathname.split('/').filter((item) => !!item)[0];

  switch (currentPath) {
    case 'wog':
      return wogRouting(token, history);
    case 'leasing':
      return leasingRouting(locationQueryArray, history);
    case 'qrpos':
      return qrposRouting(formIdQuerry, history);
    default:
      return history.push(`${ROUTES.wog.installment.ERROR}/${Date.now()}`);
  }
}
