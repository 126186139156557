/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./WCSVerificationAccepted.module.scss";
import { ReactComponent as Accepted } from "../../../../assets/images/wcs/accepted.svg";
import WCSButton from "../../../../components/Buttons/WCSButton/WCSButton";
import { config } from "../../../../core/_consts/config";
import { wogInstallmentRouting } from "../../../../utils/routesProvider";
import { ROUTES } from "../../../../core/_consts/routes";
import LoaderComponent from "../../../../components/Loader/Loader";

const WCSVerificationAccepted = () => {
  const location = useLocation();
  const history = useHistory();
  const periods = ["15 хвилин", "1 робочого дня"];
  const [wcsState, setWcsState] = useState();
  const [isUserConfirm, setIsUserConfirm] = useState(false);
  const [period, setPeriod] = useState("");
  const [token, setToken] = useState("");

  const updateAction = useCallback(async () => {
    if (isUserConfirm) return;
    setIsUserConfirm(true);
    const url = config.url.WCS_CHECK_TOKEN;

    const response = await fetch(`${url}?token=${token}`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        origin: "*actinia.tech",
        "Content-Type": "application/json",
        Cookie: "deviceFP=15914eaa3bd22928276cd5ddf4a7f1b13901758a",
      },
      redirect: "follow",
      body: JSON.stringify({ token }),
    });
    const res = await response.json();
    console.log("res: verification", res);
    const data = res.data?.data;

    if (JSON.stringify(res).includes("error") || res.data?.httpStatus === 500) {
      setIsUserConfirm(false);
      if (res.data?.error.code === 301) {
        history.push(
          `${ROUTES.wog.installment.verification.DECLINED}/${Date.now()}`,
          { token }
        );
      } else {
        history.push(`${ROUTES.wog.installment.CANCELED}/${Date.now()}`);
      }
    }
    if (res.data?.action !== "wait") {
      setIsUserConfirm(false);
      const parameters = { ...data, action: res.data.action, token };
      // eslint-disable-next-line consistent-return
      return wogInstallmentRouting(parameters, history);
    }
    setIsUserConfirm(false);
  }, [history, isUserConfirm, token]);

  useEffect(() => {
    let activation_type_id: number;

    // eslint-disable-next-line no-prototype-builtins
    if ((location.state as any)?.hasOwnProperty("routingParameters")) {
      const { routingParameters } = location.state as any;
      activation_type_id = routingParameters.activation_type_id;
      setWcsState(() => {
        const newState = { ...routingParameters };
        return newState;
      });
      setToken(() => {
        // eslint-disable-next-line no-shadow
        const { token } = routingParameters;
        return token;
      });
    } else {
      activation_type_id = (location.state as any)?.activation_type_id;
    }

    setPeriod(() => {
      const currentPeriod = activation_type_id === 1 ? periods[0] : periods[1];
      return currentPeriod;
    });
    // Don't write dependencies
  }, []);

  // useEffect(() => {
  //   let interval: any;

  //   if (token) {
  //     interval = setInterval(updateAction, 5000);
  //   }
  //   return () => clearInterval(interval);
  // }, [token]);

  return (
    <div className={styles.accepted}>
      {isUserConfirm && (
        <div className={styles.overlay}>
          <LoaderComponent />
        </div>
      )}
      <div className={styles.accepted__description}>
        {!!period && <span>Ваша заявка прийнята.</span>}
        <span>Очікуйте повідомлення протягом {period}.</span>
      </div>
      <div className={styles.accepted__image}>
        <Accepted />
      </div>
      <div className={styles.accepted__button}>
        <WCSButton
          type="button"
          buttonText="оновити"
          handleClick={updateAction}
          isDisabled={isUserConfirm}
        />
      </div>
    </div>
  );
};

export default WCSVerificationAccepted;
