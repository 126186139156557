import React from 'react';
import { useHistory } from 'react-router-dom';
import './ContinueButton.style.scss';
// import { ROUTES } from '../../core/_consts/routes.ts';

interface IContinueButton {
  data?: any;
  handleAmountClick?: (data: any) => void;
  buttonText?: string;
  isDisabled?: boolean;
  onClick?: (e: any) => void;
  token?: string;
  type: 'button' | 'submit' | 'reset' | undefined;
}

const ContinueButton: React.FC<IContinueButton> = ({
  buttonText,
  isDisabled,
  token,
  onClick,
  type,
}: IContinueButton) => {
  const history = useHistory();

  // const onClick = useCallback(() => {
  //   if (data !== undefined) {
  //     handleAmountClick(data);
  //   } else {
  //     history.push(`leasing?token=${token}`);
  //   }
  // }, [data]);

  return (
    // eslint-disable-next-line react/button-has-type
    <button id="nav" type={type} onClick={onClick} disabled={isDisabled} className="button button_submit">
      {buttonText}
    </button>
  );
};

export default ContinueButton;
