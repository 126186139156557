/* eslint-disable no-undef */
const prod = {
  url: {
    API_URL: 'https://api.users.actinia.com.ua/v1',
    GET_FIELD_URL: 'https://api.users.actinia.com.ua/v1/field/view',
    CREATE_INVOICE: 'https://api.clients.actinia.com.ua/v1/invoiceForm/create',
    CHECK_STATUS: 'https://api.clients.actinia.com.ua/v1/invoice/status',
    SESSION_GET: 'https://api.clients.actinia.com.ua/v1/session/get',
    ACCOUNT_SYSTEM_CHECK_TOKEN_URL: 'https://api.users.actinia.com.ua/v1/leasing/account-system-transport',
    // ACCOUNT_SYSTEM_CHECK_TOKEN_URL: 'https://localhost:8080/account-system-transport',
    ACCOUNT_SYSTEM_CALLBACK_URL: 'https://leasing.actinia.tech/api/v1/payment/callback',
    WCS_CHECK_TOKEN: 'https://api.users.actinia.com.ua/v1/wcs/installment/wcs-transport',
  },
};

const sandbox = {
  url: {
    API_URL: 'https://api.users.sandbox.actinia.tech/v1',
    GET_FIELD_URL: 'https://api.users.sandbox.actinia.tech/v1/field/view',
    CREATE_INVOICE: 'https://api.clients.sandbox.actinia.tech/v1/invoiceForm/create',
    CHECK_STATUS: 'https://api.clients.sandbox.actinia.tech/v1/invoice/status',
    SESSION_GET: 'https://api.clients.sandbox.actinia.tech/v1/session/get',
    ACCOUNT_SYSTEM_CHECK_TOKEN_URL: 'https://api.users.sandbox.actinia.tech/v1/leasing/account-system-transport',
    ACCOUNT_SYSTEM_CALLBACK_URL: 'https://leasing-test.actinia.tech/api/v1/payment/callback',
    WCS_CHECK_TOKEN: 'https://api.users.sandbox.actinia.tech/v1/wcs/installment/wcs-transport',
  },
};

const dev = {
  url: {
    API_URL: 'http://localhost:8080',
    GET_FIELD_URL: 'http://localhost:8082/v1/field/view',
    CREATE_INVOICE: 'http://localhost:8085/v1/invoiceForm/create',
    CHECK_STATUS: 'http://localhost:8085/v1/invoice/status',
    SESSION_GET: 'http://localhost:8085/v1/session/get',
    // API_URL: 'http://localhost:8080',
    // For testing with UserApi
    // ACCOUNT_SYSTEM_CHECK_TOKEN_URL: 'http://localhost:8088/v1/leasing/account-system-transport',
    ACCOUNT_SYSTEM_CHECK_TOKEN_URL: 'http://localhost:8088/leasing/account-system-transport',
    ACCOUNT_SYSTEM_CALLBACK_URL: 'https://leasing-test.actinia.tech/api/v1/payment/callback',
    WCS_CHECK_TOKEN: 'http://localhost:8082/v1/wcs/installment/wcs-transport',
  },
};

const beta = {
  url: {
    API_URL: 'https://api.users.beta.actinia.tech/v1',
    GET_FIELD_URL: 'https://api.users.beta.actinia.tech/v1/field/view',
    CREATE_INVOICE: 'https://api.clients.beta.actinia.tech/v1/invoiceForm/create',
    CHECK_STATUS: 'https://api.clients.beta.actinia.tech/v1/invoice/status',
    SESSION_GET: 'https://api.clients.beta.actinia.tech/v1/session/get',
    // API_URL: 'http://localhost:8080',
    // For testing with UserApi
    // ACCOUNT_SYSTEM_CHECK_TOKEN_URL: 'http://localhost:8088/v1/leasing/account-system-transport',
    ACCOUNT_SYSTEM_CHECK_TOKEN_URL: 'http://localhost:8088/leasing/account-system-transport',
    ACCOUNT_SYSTEM_CALLBACK_URL: 'https://leasing-test.actinia.tech/api/v1/payment/callback',
    WCS_CHECK_TOKEN: 'http://localhost:8082/v1/wcs/installment/wcs-transport',
  },
};

const config = dev;

switch (process.env.NODE_ENV) {
  case 'development':
    config.url.API_URL = dev.url.API_URL;
    config.url.GET_FIELD_URL = dev.url.GET_FIELD_URL;
    config.url.CREATE_INVOICE = dev.url.CREATE_INVOICE;
    config.url.CHECK_STATUS = dev.url.CHECK_STATUS;
    config.url.SESSION_GET = dev.url.SESSION_GET;
    config.url.ACCOUNT_SYSTEM_CALLBACK_URL = dev.url.ACCOUNT_SYSTEM_CALLBACK_URL;
    config.url.ACCOUNT_SYSTEM_CHECK_TOKEN_URL = dev.url.ACCOUNT_SYSTEM_CHECK_TOKEN_URL;
    config.url.WCS_CHECK_TOKEN = dev.url.WCS_CHECK_TOKEN;
    break;
  case 'sandbox':
    config.url.API_URL = sandbox.url.API_URL;
    config.url.GET_FIELD_URL = sandbox.url.GET_FIELD_URL;
    config.url.CREATE_INVOICE = sandbox.url.CREATE_INVOICE;
    config.url.CHECK_STATUS = sandbox.url.CHECK_STATUS;
    config.url.SESSION_GET = sandbox.url.SESSION_GET;
    config.url.ACCOUNT_SYSTEM_CALLBACK_URL = sandbox.url.ACCOUNT_SYSTEM_CALLBACK_URL;
    config.url.ACCOUNT_SYSTEM_CHECK_TOKEN_URL = sandbox.url.ACCOUNT_SYSTEM_CHECK_TOKEN_URL;
    config.url.WCS_CHECK_TOKEN = sandbox.url.WCS_CHECK_TOKEN;
    break;
  case 'production':
    config.url.API_URL = prod.url.API_URL;
    config.url.GET_FIELD_URL = prod.url.GET_FIELD_URL;
    config.url.CREATE_INVOICE = prod.url.CREATE_INVOICE;
    config.url.CHECK_STATUS = prod.url.CHECK_STATUS;
    config.url.SESSION_GET = prod.url.SESSION_GET;
    config.url.ACCOUNT_SYSTEM_CALLBACK_URL = prod.url.ACCOUNT_SYSTEM_CALLBACK_URL;
    config.url.ACCOUNT_SYSTEM_CHECK_TOKEN_URL = prod.url.ACCOUNT_SYSTEM_CHECK_TOKEN_URL;
    config.url.WCS_CHECK_TOKEN = prod.url.WCS_CHECK_TOKEN;
    break;
  case 'beta':
    config.url.API_URL = beta.url.API_URL;
    config.url.GET_FIELD_URL = beta.url.GET_FIELD_URL;
    config.url.CREATE_INVOICE = beta.url.CREATE_INVOICE;
    config.url.CHECK_STATUS = beta.url.CHECK_STATUS;
    config.url.SESSION_GET = beta.url.SESSION_GET;
    break;
  default:
    config.url.API_URL = prod.url.API_URL;
}

if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'sandbox') {
  config.url.API_URL = sandbox.url.API_URL;
  config.url.GET_FIELD_URL = sandbox.url.GET_FIELD_URL;
  config.url.CREATE_INVOICE = sandbox.url.CREATE_INVOICE;
  config.url.CHECK_STATUS = sandbox.url.CHECK_STATUS;
  config.url.SESSION_GET = sandbox.url.SESSION_GET;
  config.url.ACCOUNT_SYSTEM_CHECK_TOKEN_URL = sandbox.url.ACCOUNT_SYSTEM_CHECK_TOKEN_URL;
  config.url.ACCOUNT_SYSTEM_CALLBACK_URL = sandbox.url.ACCOUNT_SYSTEM_CALLBACK_URL;
  config.url.WCS_CHECK_TOKEN = sandbox.url.WCS_CHECK_TOKEN;
}

if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'beta') {
  config.url.API_URL = beta.url.API_URL;
  config.url.GET_FIELD_URL = beta.url.GET_FIELD_URL;
  config.url.CREATE_INVOICE = beta.url.CREATE_INVOICE;
  config.url.CHECK_STATUS = beta.url.CHECK_STATUS;
  config.url.SESSION_GET = beta.url.SESSION_GET;
}

if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'production') {
  config.url.API_URL = prod.url.API_URL;
  config.url.GET_FIELD_URL = prod.url.GET_FIELD_URL;
  config.url.CREATE_INVOICE = prod.url.CREATE_INVOICE;
  config.url.CHECK_STATUS = prod.url.CHECK_STATUS;
  config.url.SESSION_GET = prod.url.SESSION_GET;
}

console.info('app Config', config, process.env.NODE_ENV, process.env.REACT_APP_CUSTOM_NODE_ENV);

export {
  // eslint-disable-next-line import/prefer-default-export
  config,
};

// export const config = process.env.NODE_ENV === 'development' ? dev : prod;.....
