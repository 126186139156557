import React, { useHistory, useLocation } from 'react-router-dom';
import styles from './WCSPaymentCancel.module.scss';
import { ReactComponent as Declined } from '../../../../assets/images/wcs/wcs-declined.svg';
import WCSButton from '../../../../components/Buttons/WCSButton/WCSButton';
import { ROUTES } from '../../../../core/_consts/routes';

const WCSPaymentCancel = () => {
  const location = useLocation();
  const history = useHistory();

  const handleClick = () => {
    const token = location.search.split('=')[1];
    history.push({
      pathname: `${ROUTES.wog.installment.verification.HOME}/${Date.now()}`,
      search: `?token=${token}`,
    });
  };
  return (
    <>
      <div className={styles.payment__cancel}>
        <div className={styles.payment__cancel__title}>
          <span>Помилка оплати.</span>
          <span> Спробуйте ще раз.</span>
        </div>
        <div className={styles.payment__cancel__icon}>
          <Declined />
        </div>
      </div>
      <div className={styles.payment__cancel__button}>
        <WCSButton
          type="button"
          buttonText="Спробувати ще раз"
          handleClick={handleClick}
        />
      </div>
    </>
  );
};

export default WCSPaymentCancel;
