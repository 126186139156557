import { useLocation } from 'react-router';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import MainPageLayout from '../../../../components/MainPageLayout/MainPageLayout';
import { ReactComponent as Check } from '../../../../assets/images/wcs/check-mark.svg';
import styles from './WCSInfo.module.scss';
import { ROUTES } from '../../../../core/_consts/routes';
import { ReactComponent as Help } from '../../../../assets/images/wcs/help-icon.svg';

const WCSInfo = (): ReactElement => {
  const location = useLocation();
  const wcsState = (location.state as any).routingParameters
    ?? JSON.parse(sessionStorage.getItem('routingParameters') as string);

  return (
    <MainPageLayout>
      <div className={styles.info}>
        <Link
          to={`${ROUTES.wog.installment.repayment.HELP}/${Date.now()}`}
          className={styles.info__help}
        >
          <Help />
        </Link>
        <div className={styles.info__header}>
          Як скористатися послугою "Пальне у розстрочку"?
        </div>
        <ul className={styles.info__list}>
          <li className={styles.info__item}>
            <span className={styles.info__icon}>
              <Check />
            </span>
            <span className={styles.info__description}>
              На головній сторінці оберіть
              {' '}
              <span>Пальне</span>
              {' '}
              у
              {' '}
              <span>WOG PAY</span>
            </span>
          </li>
          <li className={styles.info__item}>
            <span className={styles.info__icon}>
              <Check />
            </span>
            <span className={styles.info__description}>
              Оберіть на мапі
              {' '}
              <span>АЗК</span>
              {' '}
              на якій будете заправляти пальне
            </span>
          </li>
          <li className={styles.info__item}>
            <span className={styles.info__icon}>
              <Check />
            </span>
            <span className={styles.info__description}>
              У способі оплати оберіть варіант
              {' '}
              <span>Сплатити потім</span>
            </span>
          </li>
          <li className={styles.info__item}>
            <span className={styles.info__icon}>
              <Check />
            </span>
            <span className={styles.info__description}>
              Здійснити заправку відповідно до дозволених лімітів
            </span>
          </li>
          <li className={styles.info__item}>
            <span className={styles.info__icon}>
              <Check />
            </span>
            <span className={styles.info__description}>
              Діапазон дозволеного ліміту від
              {' '}
              <span>
                {localStorage.getItem('limitMin')
                  ?? wcsState.limit_min?.toFixed(2)}
                {' '}
                грн
              </span>
              {' '}
              до
              {' '}
              <span>
                {localStorage.getItem('limitMax')
                  ?? wcsState.limit_max?.toFixed(2)}
                {' '}
                грн
              </span>
            </span>
          </li>
          <li className={styles.info__item}>
            <span className={styles.info__icon}>
              <Check />
            </span>
            <span className={styles.info__description}>
              Після використання ліміту можливо його оплатити обравши у меню
              послуг
              {' '}
              <span>"Заправляй зараз, а сплачуй потім"</span>
            </span>
          </li>
        </ul>
      </div>
    </MainPageLayout>
  );
};

export default WCSInfo;
