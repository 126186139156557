import { TextField, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

import './TextInput.style.scss';

interface TextInputProps {
  labelName: string | null;
  hidden?: boolean;
  placeholder: string;
  error?: boolean | undefined;
  disabled?: boolean;
  onBlur?: (e: any) => void;
  value?: string,
  name?: string,
  onChange?: (e: any) => void,
  props?: {
    children?: React.ReactNode;
  };
}

const TextInput: FC<TextInputProps> = ({
  labelName,
  placeholder,
  error,
  disabled,
  hidden,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onBlur = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  ...props
}) => {
  const useStyles = makeStyles({
    formControl: {
      minWidth: '283px',
      marginBottom: '16px',
      border: 'none',
      appearance: 'none',
      flexDirection: 'column',
      boxSizing: 'border-box',
      fontFamily: 'Montserrat',
      color: '#828282',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '22px',

      '& input': {
        height: 48,
        borderRadius: 4,
        boxSizing: 'border-box',
        background: '#EFEFEF',
        textAlign: 'center',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&:hover': {
        boxShadow: '0px 10px 10px 0px rgb(0 0 0 / 10%)',
      },
    },
  });
  const classes = useStyles();

  return (
    <div className="input-text">
      <div className="input-text__label">{labelName}</div>
      <TextField
        type="text"
        variant="outlined"
        className={classes.formControl}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
        onBlur={onBlur}
        hidden={hidden}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export default TextInput;
