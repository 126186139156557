import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as Download } from '../../assets/images/wcs/download.svg';
import { IWCSForm } from '../../pages/wog/installment/WCSVerification/WCSVerification';
import { ReactComponent as Camera } from '../../assets/images/wcs/camera.svg';
import { FILE_SIZE, SUPPORTED_FORMATS } from '../../core/_consts/constants';
import { ReactComponent as Photo } from '../../assets/images/wcs/photo-camera.svg';

import styles from './FileInput.module.scss';

interface FileInputProps {
  setImageBlob: (data: IWCSForm) => any;
  handleError: (error: boolean) => any;
  error?: boolean;
  setFieldValue: (
    field: string,
    value: string | ArrayBuffer | null,
    shouldValidate?: boolean | undefined
  ) => void;
  fieldName: string;
  setIsUploadedPhoto: React.Dispatch<React.SetStateAction<boolean>>;
  isUploadedPhoto: boolean;
}

const FileInput: FC<FileInputProps> = ({
  setImageBlob,
  handleError,
  setFieldValue,
  fieldName,
  setIsUploadedPhoto,
  isUploadedPhoto,
}: FileInputProps) => {
  const [image, setImage] = useState<string | ArrayBuffer | null>('');
  const [nameImage, setNameImage] = useState('');

  const getFile = (event: any) => {
    const file = event.target.files[0];
    const fileType = file.type;

    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      setImage(reader.result);
      setNameImage(file.name);
      setImageBlob({ identCode: '', driverLicenceImage: reader.result });
      setFieldValue(fieldName, reader.result);
      setIsUploadedPhoto(true);
      handleError(false);
      if (file.size > FILE_SIZE || !SUPPORTED_FORMATS.includes(fileType)) {
        handleError(true);
        setIsUploadedPhoto(false);
      }
    };
  };

  return (
    <div className={styles.upload}>
      <div className={styles.upload__main}>посвідчення водія</div>
      {!isUploadedPhoto && (
        <>
          <div className={styles.upload__form}>
            <label htmlFor="upload" className={styles.upload__label}>
              <input
                type="file"
                id="upload"
                accept="image/*"
                capture="environment"
                className={styles.upload__input}
                onChange={getFile}
              />
              <div className={styles.upload__content}>
                <span>
                  <Photo />
                </span>
                <span className={styles.upload__title}>Зробити фото</span>
              </div>
            </label>
            <label htmlFor="upload-file" className={styles.upload__label}>
              <input
                type="file"
                id="upload-file"
                accept=".png, .jpg, .jpeg"
                className={styles.upload__input}
                onChange={getFile}
              />
              <div className={styles.upload__content}>
                <span>
                  <Download />
                </span>
                <span className={styles.upload__title}>Завантажити</span>
              </div>
            </label>
          </div>
          <span className={styles.upload__error}>max 20 МБ</span>
        </>
      )}
      {isUploadedPhoto && (
        <>
          <div className={styles.upload__image}>
            <img src={image as string} alt="" />
            <div className={styles.overlay}>
              <div className={styles.overlay__button}>
                <label htmlFor="upload-file" className={styles.overlay__label}>
                  <Camera className={styles.smaller} />
                  <input
                    type="file"
                    id="upload-file"
                    accept="image/*"
                    className={styles.upload__input}
                    onChange={getFile}
                  />
                </label>
              </div>
            </div>
          </div>
          <span className={styles.upload__name}>{nameImage}</span>
        </>
      )}
    </div>
  );
};

export default FileInput;
