import { cleanup } from '@testing-library/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Accepted } from '../../../../assets/images/wcs/accepted.svg';
import WCSButton from '../../../../components/Buttons/WCSButton/WCSButton';
import { config } from '../../../../core/_consts/config';
import { ROUTES } from '../../../../core/_consts/routes';
import { wogInstallmentRouting } from '../../../../utils/routesProvider';
import styles from './WCSPaymentAccepted.module.scss';

const WCSPaymentAccepted = () => {
  const location = useLocation();
  const history = useHistory();
  const params = location.search.split('&');
  const token = params[0].split('=')[1];
  const remains = +params[1].split('=')[1];
  const [wcsState, setWcsState] = useState();
  console.log('location: ', location.state, params, token);

  const getInfo = useCallback(async () => {
    const url = config.url.WCS_CHECK_TOKEN;

    const response = await fetch(`${url}?token=${token}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        origin: '*actinia.tech',
        'Content-Type': 'application/json',
        Cookie: 'deviceFP=15914eaa3bd22928276cd5ddf4a7f1b13901758a',
      },
      redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify({ token }),
    });

    const res = await response.json();
    const data = res.data?.data;
    if (JSON.stringify(res).includes('error')) {
      history.push(`${ROUTES.wog.installment.CANCELED}/${Date.now()}`);
    } else {
      setWcsState(() => {
        const newState = {
          ...data,
          action: res.data.action,
          token,
        };

        return newState;
      });
    }
  }, [history, token]);

  // useEffect(() => {
  //   if (wcsState) {
  //     wogInstallmentRouting(wcsState as any, history);
  //   }

  //   return () => cleanup();
  // }, [wcsState]);

  useEffect(() => {
    if (token) {
      getInfo();
    }
  }, []);

  const handleClick = () => {
    if (remains === 0) {
      // wogInstallmentRouting(wcsState as any, history);
      history.push(`${ROUTES.wog.installment.repayment.INFO}/${Date.now()}`, {
        routingParameters: wcsState,
      });
    } else {
      history.push({
        pathname: `${ROUTES.wog.installment.repayment.HOME}/${Date.now()}`,
        search: `?token=${token}`,
        state: {
          routingParameters: wcsState,
        },
      });
    }
  };

  return (
    <div className={styles.payment__accepted}>
      {remains ? (
        <div className={styles.payment__accepted__title}>
          <span>Часткова оплата виконано успішно.</span>
          <span>
            Залишок для погашення складає
            {' '}
            <span className={styles.payment__accepted__remains}>
              {remains.toFixed(2)}
            </span>
            {' '}
            грн.
          </span>
        </div>
      ) : (
        <div className={styles.payment__accepted__title}>
          <span>Оплата пройшла успішно! </span>
          <span> Можете повторно скористатися послугою.</span>
        </div>
      )}
      <div className={styles.payment__accepted__icon}>
        <Accepted />
      </div>
      <div className={styles.payment__accepted__button}>
        <WCSButton
          type="button"
          buttonText={remains ? 'перейти до оплати' : 'скористатись ще раз'}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

export default WCSPaymentAccepted;
