import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { TextField, withStyles } from '@material-ui/core';
import { FieldInputProps } from 'formik';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

import styles from './WCSTextInput.module.scss';

interface WCSTextInputProps {
  color?: string;
  labelName: string;
  field?: FieldInputProps<any>;
  type: string;
  error?: boolean;
  disabled?: boolean;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  value: any;
  inputProps?: any;
  classes?: any;
  inputMask?: boolean;
  validationSchema?: (value: any) => boolean;
  mask?: any;
  width?: number;
}

const WCSTextInput: FC<WCSTextInputProps> = ({
  labelName,
  error: errorInit = false,
  color = '#02593D',
  disabled,
  field,
  type,
  value: valueInit,
  inputProps,
  onChange,
  onBlur,
  classes,
  inputMask,
  mask,
  validationSchema,
  width = 289,
}: WCSTextInputProps) => {
  const [value, setValue] = useState(valueInit);
  const [error, setError] = useState(errorInit);
  const [borderColor, setBorderColor] = useState(color);
  // const [isInitRender, setInitRender] = useState(true);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onChangeInit: (e: any) => void = onChange || field?.onChange;

  // useEffect(() => {
  //   console.log("errorInit: ", errorInit);
  //   if (errorInit) {
  //     setError(errorInit);
  //     setBorderColor("red");
  //   }
  // }, [errorInit]);

  const onFocusInput = useCallback(() => {
    const input: HTMLInputElement = document.getElementById(inputProps?.id) as HTMLInputElement;

    // console.info(`${inputProps.id}`, input);
    input.focus();
    if (input.type === 'text') {
      input.selectionStart = input.value.length;
    }
  }, [inputProps?.id]);

  useEffect(() => {
    onFocusInput();
  });

  useEffect(() => {
    if (valueInit !== value) {
      setValue(valueInit);

      if (validationSchema && !validationSchema(valueInit)) {
        setBorderColor('red');
        setError(true);
      } else {
        setBorderColor(color);
        setError(false);
      }
    }
  }, [value, valueInit, color, validationSchema]);

  const CustomTextField = useMemo(
    () => withStyles({
      root: {
        width: `${width}px`,
        color: '#333333',

        '& label.Mui-focused': {
          color: borderColor,
        },
        '& .MuiInputBase-input.MuiInput-input': {
          'input::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          'input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '-moz-appearance': 'textfield',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: borderColor,
          borderWidth: '2px',
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: borderColor,
          borderWidth: '2px',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: '#02593D',
          },
        },
      },
    })(TextField),
    [borderColor],
  );

  const onBlurAction = (e: any) => {
    if (e.target.value.length !== 10) {
      setError(true);
      setBorderColor('red');

      onFocusInput();
    }
  };

  const onChangeData = useCallback(
    (e: any) => {
      // console.info(`${e.target.name}`, e.target, field)

      // eslint-disable-next-line no-shadow
      const { value } = e.target;

      setValue(value);

      if (validationSchema) {
        const isValid = validationSchema(value);

        console.info('isValid ---', isValid);

        if (!isValid) {
          setBorderColor('red');
          setError(true);
        } else {
          setBorderColor('green');
          setError(false);
        }
      }

      onChangeInit(e);

      onFocusInput();
    },
    [onChangeInit, onFocusInput, validationSchema],
  );

  return (
    <div>
      <div className={styles.label}>{labelName}</div>
      {inputMask && (
        <InputMask
          disabled={disabled}
          mask={mask}
          value={value}
          alwaysShowMask={false}
          maskPlaceholder={null}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment  */
          // @ts-ignore
          maskChar=""
          // onChange={onChangeData}
          onBlur={onBlurAction}
          onChange={onChangeData}
          // className={styles.input}
          className={classNames(styles.inputDefault, {
            [styles.errorInput]: error,
          })}
        >
          {() => (
            <CustomTextField
              className={classNames(styles.inputDefault, {
                [styles.errorInput]: error,
              })}
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...field}
              placeholder=""
              error={error}
              disabled={disabled}
              type={type}
              onChange={onChangeData}
              onBlur={onBlurAction}
              classes={classes}
              value={value}
              inputProps={inputProps}
            />
          )}
        </InputMask>
      )}
      {!inputMask && (
        <CustomTextField
          className={classNames(styles.inputDefault, {
            [styles.errorInput]: error,
          })}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...field}
          error={error}
          disabled={disabled}
          type={type}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          onBlur={onBlur}
          classes={classes}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          value={value}
          onChange={onChangeData}
          inputProps={inputProps}
        />
      )}
    </div>
  );
};

export default WCSTextInput;
