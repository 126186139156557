import React, { ReactElement } from 'react';

import styles from '../WCSPaymentCancel/WCSPaymentCancel.module.scss';
import { ReactComponent as Declined } from '../../../../assets/images/wcs/wcs-declined.svg';

const WCSError = (): ReactElement => (
  <>
    <div className={styles.payment__cancel}>
      <div className={styles.payment__cancel__title}>
        <span>Помилка отримання даних.</span>
      </div>
      <div className={styles.payment__cancel__icon}>
        <Declined />
      </div>
    </div>
  </>
);

export default WCSError;
