/* eslint-disable */
import { Field, FieldProps, Form, Formik, validateYupSchema } from "formik";
import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import * as Yup from "yup";
import { Link, useHistory, useLocation } from "react-router-dom";
import WCSButton from "../../../../components/Buttons/WCSButton/WCSButton";
import FileInput from "../../../../components/FileInput/FileInput";
import MainPageLayout from "../../../../components/MainPageLayout/MainPageLayout";
import WCSContent from "../../../../components/WCSContent/WCSContent";
import WCSOffer from "../../../../components/WCSOffer/WCSOffer";
import WCSTextInput from "../../../../components/WCSTextInput/WCSTextInput";
import styles from "./WCSVerification.module.scss";
import { config } from "../../../../core/_consts/config";
import { ROUTES } from "../../../../core/_consts/routes";
import LoaderComponent from "../../../../components/Loader/Loader";
import { ReactComponent as Help } from "../../../../assets/images/wcs/help-icon.svg";
import WCSActivationInfo from "../WCSActivationInfo/WCSActivationInfo";
import { globalErrorHanding } from "../../../../utils/routesProvider";
// import {notify} from "../../../utils/notification";

export interface IWCSForm {
  identCode: string;
  driverLicenceImage: string | ArrayBuffer | null;
}

const ValidationSchemaWCS = Yup.object().shape({
  // identCode: Yup.number().required(),
  // driveLicenceImage: Yup.string().required(),
  // .test((value) => value && value.size <= FILE_SIZE)
  // .test((value) => value && SUPPORTED_FORMATS.includes(value.type)),
});

const WCSVerification = () => {
  const cx = classNames.bind(styles);
  const storage = localStorage;
  const session = sessionStorage;
  const history = useHistory();
  const location = useLocation();
  const height = 700;
  const wcsState =
    (location.state as any).routingParameters ??
    JSON.parse(session.getItem("routingParameters") as string);

  const [checked, setChecked] = useState(true);

  const [accountData, setAccountData] = useState({
    driverLicenceImage: "",
    identCode: storage.getItem("identCode")
      ? (storage.getItem("identCode") as string)
      : "",
  });
  const [hidden, setHidden] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [isUserConfirm, setIsUserConfirm] = useState(false);
  const [isUploadedPhoto, setIsUploadedPhoto] = useState(false);
  const [isChangePage, setIsChangePage] = useState(false);
  const [changeButton, setChangeButton] = useState(false);

  const changCheckbox = (value: boolean) => {
    setChecked(value);
  };

  useEffect(() => {
    window.addEventListener("resize", handleKeyboard);

    return () => window.removeEventListener("resize", handleKeyboard);
  }, []);

  useEffect(() => {
    if (!isUploadedPhoto) setHidden(false);
  }, [accountData.driverLicenceImage, isUploadedPhoto]);

  const handleSubmit = async ({ identCode, driverLicenceImage }: IWCSForm) => {
    setIsUserConfirm(true);
    storage.setItem("identCode", identCode);
    const url = `${config.url.API_URL}/wcs/installment/wog-withverify`;

    // if (fileError ||
    //       !checked ||
    //       identCode.toString().length !== 10 ||
    //       driverLicenceImage?.toString().length
    // ) {
    //
    // eslint-disable-next-line max-len
    //   const error = identCode ? 'Невірно введений ІПН' : 'Фото водійського посвідчення не завантажено не завантажено'
    //
    //   notify.error(`${error}`)
    //
    //   return
    // }

    // eslint-disable-next-line camelcase
    const { wog_id, pride_id, phone } = wcsState;
    const requestBody = {
      wog_id,
      pride_id,
      phone,
      documents: driverLicenceImage,
      inn: `${identCode}`,
    };

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        origin: "*actinia.tech",
        "Content-Type": "application/json",
        Cookie: "deviceFP=15914eaa3bd22928276cd5ddf4a7f1b13901758a",
      },
      redirect: "follow", // manual, *follow, error
      // referrerPolicy: "no-referrer", // no-referrer, *client
      body: JSON.stringify(requestBody),
    });

    const res = await response.json();
    console.log("res: ", res);
    console.log("data: ", res.data);

    if (res.data?.success) {
      setIsUserConfirm(false);
      localStorage.removeItem("identCode");
      history.push(
        `${
          ROUTES.wog.installment.verification.ACCEPTED_WITH_VERIFICATION
        }/${Date.now()}`,
        {
          routingParameters: {
            activation_type_id: 2,
            token: wcsState.token,
          },
        }
      );
    } else {
      setIsUserConfirm(false);
      globalErrorHanding(res.data?.error, wcsState.token, history);
    }
  };

  const handleClick = () => {
    setIsChangePage(true);
    storage.setItem("isShowActivationInfo", "true");
  };

  const handleUpdateData = (data: IWCSForm) => {
    setAccountData((prevState) => {
      const newState = {
        ...prevState,
        identCode: data.identCode,
        driverLicenseImage: data.driverLicenceImage as string,
      };

      if (newState) {
        setHidden(true);
      }

      return newState;
    });
  };

  const handleFileError = (error: boolean) => {
    setFileError(error);
  };

  const handleKeyboard = (e: any) => {
    if (e.target.innerHeight < height) {
      setChangeButton(true);
      const button = document.getElementById("button") as HTMLButtonElement;
      button.style.setProperty("bottom", "unset");
      button.style.setProperty("margin", "5px");
    }
  };

  return (
    <>
      {!storage.getItem("isShowActivationInfo") && (
        <WCSActivationInfo changePage={handleClick} />
      )}
      {wcsState && !!storage.getItem("isShowActivationInfo") && (
        <MainPageLayout>
          <Link
            to={`${ROUTES.wog.installment.repayment.HELP}/${Date.now()}`}
            className={styles.help}
          >
            <Help />
          </Link>
          {isUserConfirm && (
            <div className={styles.overlay}>
              <LoaderComponent />
            </div>
          )}
          <div className={styles.container}>
            {!hidden && <WCSContent limit={wcsState.limit_max} />}
          </div>
          <div>
            <Formik
              initialValues={accountData}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
              validationSchema={ValidationSchemaWCS}
            >
              {({ errors, setFieldValue, values, touched }) => (
                <Form
                  className={cx(styles.form, {
                    [styles.form__hidden]: hidden,
                  })}
                >
                  <div className={styles.form__ipn}>
                    <div className={styles.form__label}>ІПН</div>
                    <Field name="identCode" component="form" key={1}>
                      {({ field }: FieldProps) => {
                        // console.info('name="identCode" ---', field)
                        const { onChange, value } = field;

                        return (
                          <WCSTextInput
                            classes={{
                              root: styles.form__input,
                            }}
                            labelName=""
                            type="text"
                            disabled={false}
                            error={!!errors.identCode}
                            mask="9999999999"
                            inputMask
                            field={field}
                            onChange={onChange}
                            value={value}
                            /* eslint-disable-next-line no-shadow */
                            validationSchema={(value: any) =>
                              value.toString().length === 10
                            }
                            inputProps={{
                              id: "identCodeWogInsVerification",
                            }}
                            width={135}
                          />
                        );
                      }}
                    </Field>
                  </div>
                  <Field name="driverLicenceImage" component="form" key={2}>
                    {({ field }: FieldProps) => (
                      <FileInput
                        setImageBlob={handleUpdateData}
                        handleError={handleFileError}
                        setFieldValue={setFieldValue}
                        fieldName={field.name}
                        setIsUploadedPhoto={setIsUploadedPhoto}
                        isUploadedPhoto={isUploadedPhoto}
                      />
                    )}
                  </Field>
                  <WCSOffer checked={checked} changeChecked={changCheckbox} />
                  <div
                    className={cx(styles.button, {
                      [styles.button__down]: hidden,
                    })}
                  >
                    <WCSButton
                      id="button"
                      type="submit"
                      buttonText="активувати"
                      isDisabled={
                        fileError ||
                        !checked ||
                        values.identCode.toString().length !== 10 ||
                        !values.driverLicenceImage.length ||
                        isUserConfirm
                      }
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </MainPageLayout>
      )}
    </>
  );
};

export default WCSVerification;
