import React from 'react';
import './App.scss';
import { Route, Switch, useHistory } from 'react-router-dom';
import LeasingFormInput from './pages/actinia/leasing/LeasingForm/LeasingFormInput';
import { ROUTES } from './core/_consts/routes';
import LeasingAccepted from './pages/actinia/leasing/LeasingAccepted/LeasingAccepted';
import LeasingDeclined from './pages/actinia/leasing/LeasingDeclined/LeasingDeclined';
import LeasingCancel from './pages/actinia/leasing/LeasingCancel/LeasingCancel';
import WCSDeclined from './pages/wog/installment/WCSDeclined/WCSDeclined';
import { distributeRoutes } from './utils/routesProvider';
import WCSMainPage from './pages/wog/installment/WCSMainPage/WCSMainPage';
import WCSVerification from './pages/wog/installment/WCSVerification/WCSVerification';
import WCSNoVerification from './pages/wog/installment/WCSNoverification/WCSNoverification';
import WCSInfo from './pages/wog/installment/WCSPageInfo/WCSInfo';
import WCSPayment from './pages/wog/installment/WCSPayment/WCSPayment';
import WCSPaymentAttempt from './pages/wog/installment/WCSPaymentAttempt/WCSPaymentAttempt';
import WCSVerificationAccepted from './pages/wog/installment/WCSVerificationAccepted/WCSVerificationAccepted';
import WCSPaymentAccepted from './pages/wog/installment/WCSPaymentAccepted/WCSPaymentAccepted';
import WCSPaymentCancel from './pages/wog/installment/WCSPaymentCancel/WCSPaymentCancel';
import WCSError from './pages/wog/installment/WCSError/WCSError';
import WCSRules from './pages/wog/installment/WCSRules/WCSRules';
import WCSGlobalError from './pages/wog/installment/WCSGlobalError/WCSGlobalError';
import WCSNotFound from './pages/wog/installment/WCSNotFound/WCSNotFound';
import InvoiceFormInput from './pages/actinia/invoice/InvoiceForm/InvoiceFormInput';

function App(): JSX.Element {
  const history = useHistory();
  distributeRoutes(window.location, history);

  return (
    <Switch>
      {/* leasing */}
      <Route exact path={ROUTES.LEASING_HOME} component={LeasingFormInput} />
      <Route exact path={ROUTES.INVOICE_HOME} component={InvoiceFormInput} />
      <Route path={ROUTES.LEASING_ACCEPTED} component={LeasingAccepted} />
      <Route path={ROUTES.LEASING_DECLINED} component={LeasingDeclined} />
      <Route path={ROUTES.CANCELED} component={LeasingCancel} />

      {/* wog.installment.verification */}
      {/* <Route
        exact
        path={ROUTES.wog.installment.verification.HOME}
        component={WCSMainPage}
      /> */}
      <Route
        exact
        path={`${ROUTES.wog.installment.verification.HOME}/:hash`}
        component={WCSMainPage}
      />
      <Route
        exact
        path={`${ROUTES.wog.installment.CANCELED}/:hash`}
        component={WCSError}
      />
      <Route
        exact
        path={`${ROUTES.wog.installment.verification.WITH_VERIFICATION}/:hash`}
        component={WCSVerification}
      />
      <Route
        exact
        path={`${ROUTES.wog.installment.verification.WITHOUT_VERIFICATION}/:hash`}
        component={WCSNoVerification}
      />
      {/* <Route
        path={ROUTES.wog.installment.verification.ACCEPTED}
        component={WCSAccepted}
      /> */}
      <Route
        path={`${ROUTES.wog.installment.verification.ACCEPTED_WITHOUT_VERIFICATION}/:hash`}
        component={WCSVerificationAccepted}
      />
      <Route
        path={`${ROUTES.wog.installment.verification.ACCEPTED_WITH_VERIFICATION}/:hash`}
        component={WCSVerificationAccepted}
      />
      <Route
        path={`${ROUTES.wog.installment.verification.DECLINED}/:hash`}
        component={WCSDeclined}
      />
      {/* wog.installment.repayment */}
      <Route
        path={`${ROUTES.wog.installment.repayment.INFO}/:hash`}
        component={WCSInfo}
      />
      <Route
        path={`${ROUTES.wog.installment.repayment.HOME}/:hash`}
        component={WCSPayment}
      />
      <Route
        exact
        path={`${ROUTES.wog.installment.repayment.DECLINED}/:hash`}
        component={WCSPaymentCancel}
      />
      <Route
        exact
        path={`${ROUTES.wog.installment.repayment.ACCEPTED}/:hash`}
        component={WCSPaymentAccepted}
      />
      <Route
        exact
        path={`${ROUTES.wog.installment.repayment.ACCEPTED_FULL}/:hash`}
        component={WCSPaymentAccepted}
      />
      <Route
        exact
        path={`${ROUTES.wog.installment.repayment.CANCELED}/:hash`}
        component={WCSPaymentCancel}
      />
      <Route
        path={`${ROUTES.wog.installment.repayment.ATTEMPT}/:hash`}
        component={WCSPaymentAttempt}
      />
      <Route
        path={`${ROUTES.wog.installment.repayment.HELP}/:hash`}
        component={WCSRules}
      />
      <Route
        path={`${ROUTES.wog.installment.ERROR}/:hash`}
        component={WCSGlobalError}
      />
      <Route component={WCSNotFound} />
    </Switch>
  );
}

export default App;
