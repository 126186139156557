import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import classNames from 'classnames';

import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

import s from './index.module.scss';
import 'react-toastify/dist/ReactToastify.css';

// const toastContainerStyle = { width: '100%' };

ReactDOM.render(
  <ErrorBoundary>
    <BrowserRouter>
      <ToastContainer
        className={classNames(s.toastifyContainer, s.toastifyDefaultProgress)}
      />
      <App />
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
