import React, { ReactNode } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import HeaderLeasing from '../Headers/HeaderLeasing/HeaderLeasing';

import './MainPageLayout.styles.scss';

type MainProps = {
  children: ReactNode;
};

const MainPageLayout = ({ children }: MainProps): JSX.Element => {
  const location = useHistory();

  return (
    <div className={cn('main', { main__background: location.location.pathname === '/qrpos' })}>
      <div className="main__children">{children}</div>
      {location.location.pathname.includes('wog') || location.location.pathname.includes('qrpos') ? <></> : <HeaderLeasing />}
    </div>
  );
};

export default MainPageLayout;
