// eslint-disable-next-line no-shadow
export enum Services {
  LEASING = 'LEASING',
  INSTALLMENT = 'INSTALLMENT',
}

export const FILE_SIZE = 20 * 1024 * 1024;
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
];

// eslint-disable-next-line no-shadow
export enum WCSRouter {
}
