import React from 'react';
import { useHistory } from 'react-router-dom';
import SuccessSvg from '../../../../assets/images/leasing/success.svg';
import MainPageLayout from '../../../../components/MainPageLayout/MainPageLayout';
// import ContinueButton from "../../components/ContinueButton";
// import Header from "../../components/Headers/Header";
import styles from './Success.module.scss';

const LeasingAccepted = (): JSX.Element => {
  const history = useHistory<any>();
  const token = history.location.state?.token;
  console.info('Success token!', token, history);

  return (
    <MainPageLayout>
      <div className={styles.content}>
        <div className={styles.content__success}>
          <div className={styles.content__title}>
            Дякуємо! Ваш платіж зарахований
          </div>
          <img
            src={SuccessSvg}
            alt="success"
            className={styles.content__image}
          />
          {/* <div className={styles.content__button}>
            <ContinueButton buttonText="Добре" type="button" />
          </div> */}
        </div>
      </div>
    </MainPageLayout>
  );
};

export default LeasingAccepted;
