/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
// import Header from "../../components/Headers/Header";
import UnFound from '../../../../assets/images/leasing/unfound.svg';
import MainPageLayout from '../../../../components/MainPageLayout/MainPageLayout';
// import {useHistory} from 'react-router-dom';

import styles from './Canceled.module.scss';

// eslint-disable-next-line
const LeasingCancel = (): JSX.Element => {
  // const history = useHistory<any>();
  // console.log('history', history);
  // const token = history.location.state.token;
  // console.info('Declined token!', token, history);

  return (
    <MainPageLayout>
      <div className={styles.content}>
        <div className={styles.content__canceled}>
          <div className={styles.content__title}>
            Помилка отримання даних від облікової системи
          </div>
          <div className={styles.content__description}>
            <span className={styles.content__text}>Телефон підтримки</span>
            <a href="callto: +380670777111" className={styles.content__phone}>
              +380670777111
            </a>
          </div>
          <img src={UnFound} alt="error" className={styles.content__image} />
        </div>
      </div>
    </MainPageLayout>
  );
};

export default LeasingCancel;
