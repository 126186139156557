import React, { FC } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core';
import styles from './WCSOffer.module.scss';

interface WSOfferProps {
  checked: boolean;
  changeChecked: (value: boolean) => void;
}

const useStyles = makeStyles({
  root: {
    '&.MuiIconButton-root': {
      padding: 0,
    },
    color: '#02593D',
    '&$checked': {
      color: '#02593D',
    },
  },
  checked: {},
});

const WCSOffer: FC<WSOfferProps> = ({
  checked,
  changeChecked,
}: WSOfferProps) => {
  const classes = useStyles();
  const handleChange = (event: any) => {
    changeChecked(event.target.checked);
  };

  return (
    <div className={styles.offer}>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        classes={{
          root: classes.root,
          checked: classes.checked,
        }}
      />
      <div className={styles.offer__text}>
        З
        {' '}
        <a href="https://wog.ua/ua/rules_plati_potim/" target="_blank" rel="noreferrer">Умовами</a>
        {' '}
        погоджуюсь.
      </div>
    </div>
  );
};

export default WCSOffer;
