import { config } from '../core/_consts/config';

function getUrl(service: string) {
  let url;

  switch (service) {
    case 'wog':
      url = config.url.WCS_CHECK_TOKEN;
      break;
    case 'leasing':
      url = config.url.ACCOUNT_SYSTEM_CHECK_TOKEN_URL;
      break;
    default:
      return null;
  }

  return url;
}

export async function verifyAndGateInitialData(
  token: string,
  service = 'leasing',
) {
  const url = getUrl(service);

  try {
    const response = await fetch(`${url}?token=${token}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        origin: '*actinia.tech',
        'Content-Type': 'application/json',
        Cookie: 'deviceFP=15914eaa3bd22928276cd5ddf4a7f1b13901758a',
      },
      redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify({ token }),
    });

    const res = await response.json();
    const data = res;

    return data;
  } catch (error) {
    console.error('verifyAndGateInitialData -', error);
    return null;
  }
}

export function createState(data: any) {
  const { action, activation_type_id = 0 } = data;
  const payload = { ...data, action };
  return payload;
}
