import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { useHistory } from 'react-router-dom';
import styles from './WCSRules.module.scss';
import { ReactComponent as ArrowDown } from '../../../../assets/images/wcs/down.svg';
import WCSButton from '../../../../components/Buttons/WCSButton/WCSButton';
import { ReactComponent as Back } from '../../../../assets/images/wcs/back.svg';

const Accordion = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {
    background: '#FFFFFF',
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#E5E5E5',
    borderBottom: '1px solid #9B9D9B',
    marginBottom: -1,
    minHeight: 42,
    '&$expanded': {
      minHeight: 56,
      backgroundColor: '#FFFFFF',
      borderBottom: '1px solid #02593D',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
  expandIcon: {
    '&.Mui-expanded': {
      '& > .MuiIconButton-label > svg > path': {
        fill: '#02593D',
      },
    },
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);

const WCSRules = () => {
  const history = useHistory();

  const goBack = () => {
    history.go(-1);
  };

  // const handleScroll = (e: any) => {
  //   const button = document.getElementById("button") as HTMLElement;
  //   if (
  //     document.body.scrollTop > 20 ||
  //     document.documentElement.scrollTop > 20
  //   ) {
  //     button.style.display = "block";
  //   } else {
  //     button.style.display = "none";
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  return (
    <div className={styles.main__accordion}>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Другу / родичу / знайомому надіслали повідомлення про доступність
            нової послуги. Чому не прислали мені? Як я можу брати участь у цій
            програмі?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Наша компанія самостійно визначає учасників програми. Як тільки
            послуга стане Вам доступна, ви отримаєте відповідне повідомлення в
            додатку.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Де можна ознайомитися з інформацією про послугу «Заправляй зараз, а
            сплачуй потім»?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            В додатку PRIDE, в розділі Послуги.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            З якою метою надаються ІПН і фото прав?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Дана інформація необхідна для визначення доступності послуги та її
            суми.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Як швидко приймається рішення про надання послуги?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Термін розгляду - до 1 робочого дня після надання інформації.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Чи можна оплатити інші товари або послуги на заправках за програмою
            "Заправляй зараз, а сплачуй потім"?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Ні, дана послуга доступна тільки для заправки автомобіля пальним.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            На який вид пального поширюється послуга?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            На будь-який вид пального.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            На яку максимальну суму можна заправитися по даній програмі?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Заправка можлива в рамках встановленого Вам ліміту (від 50 до 1000
            грн. або від 50 до 2000 грн).
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Чи можна збільшити максимальну суму ліміту заправки?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            На даний момент максимальні ліміти - 1000 грн або 2000 грн. При зміні
            лімітів Вам буде надіслано повідомлення у додаток.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            На яку мінімальну суму можна заправитися за програмою "Заправляй
            зараз, а сплачуй потім"?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Мінімальна сума заправки - 100 грн.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Чи можна в один день заправитися по даній програмі 2 і більше разів?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Заправка за даною програмою можлива один раз на добу і на одноразову
            суму понад 100 грн. (До 600 грн або 1200 грн в залежності від
            встановленого Вам ліміту). Наступна заправка за програмою «Заправляй
            зараз, а сплачуй потім» буде доступна тільки після погашення суми
            заборгованості. Погашення можливо на наступний день починаючи з
            00:00.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Як швидко відновлюється послуга після погашення заборгованості?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Відразу після оплати.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Як і коли я можу погасити заборгованість?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Погашення заборгованості можливо на наступний день починаючи з 00:00
            після заправки з використанням послуги. Розмір заборгованості можна
            подивитися і погасити в додатку в розділі Послуги за допомогою вже
            наявних інструментів оплати MasterPass, ApplePay, GooglePay, а також
            будь-якою прив'язаною картою.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Скільки часу дається на погашення заборгованості?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Відстрочка надається на термін до 14 календарних днів. За три дні до
            закінчення терміну Ви в додатку отримаєте повідомлення про
            необхідність погашення.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Чи можна оплачувати суму заборгованості частинами?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Так, мінімальна сума оплати 20 відсотків від суми використаного
            ліміту.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Чи можна оплатити суму заборгованості достроково?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Так, Ви можете оплатити заборгованість достроково, починаючи з
            наступного дня 00:00 після заправки.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Чи можливо скористатися знову послугою якщо погашення пройшло
            частково?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Ні, тільки після повного погашення.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Чи передбачені штрафні санкції за несвоєчасне погашення
            заборгованості?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Питання повернення заборгованості вирішується в установленому
            законом порядку.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Що буде, якщо не погасити заборгованість?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            До боржника будуть застосовуватися встановлені законом заходи
            впливу.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <div className={styles.main__title}>
            Де я можу ознайомитись із правилами участі у програмі?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.main__bottom__description}>
            Ознайомитись із правила користування сервісом можно
            <a href="https://wog.ua/ua/rules_plati_potim/" target="_blank" rel="noreferrer">
              тут
            </a>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={styles.main__button} onClick={goBack} id="button">
        <Back />
      </div>
    </div>
  );
};

export default WCSRules;
