import React, { Component } from 'react';
import WCSGlobalError from '../../pages/wog/installment/WCSGlobalError/WCSGlobalError';

export default class ErrorBoundary extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { state, props } = this;
    if (state.hasError) {
      return <WCSGlobalError />;
    }

    return props.children;
  }
}
