import {
  Field, FieldProps, Form, Formik,
} from 'formik';
import React, {
  ChangeEvent,
  FC, ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Yup from 'yup';
import { Link, useHistory, useLocation } from 'react-router-dom';
import InputRange from '../../../../components/InputRange/InputRange';
import MainPageLayout from '../../../../components/MainPageLayout/MainPageLayout';
import WCSTextInput from '../../../../components/WCSTextInput/WCSTextInput';
import WCSButton from '../../../../components/Buttons/WCSButton/WCSButton';
import styles from './WCSPayment.module.scss';
import { ReactComponent as Icon } from '../../../../assets/images/wcs/payment.svg';
import { IWCSAttempt, IWCSCompensation } from '../../../../utils/routesProvider';
import { ReactComponent as Currency } from '../../../../assets/images/wcs/ua.svg';
import { config } from '../../../../core/_consts/config';
import { ROUTES } from '../../../../core/_consts/routes';
import LoaderComponent from '../../../../components/Loader/Loader';
import { ReactComponent as Help } from '../../../../assets/images/wcs/help-icon.svg';

interface WCSPayment {
  payment: number | null;
}

const initData: WCSPayment = {
  payment: null,
};

const validationSchemaPayment = Yup.object().shape({
  contractNumber: Yup.string().required(),
});

const WCSPayment = (): ReactElement => {
  const history = useHistory();
  const location = useLocation();

  // eslint-disable-next-line max-len
  const wcsState = sessionStorage?.getItem?.('routingParameters') ? JSON.parse?.(sessionStorage?.getItem?.('routingParameters') as string) : (location.state as any).routingParameters;
  const allName = `${wcsState.fname} ${wcsState.lname}`;
  const [value, setValue] = useState<number>(wcsState.owing_sum as number);
  const [isUserConfirm, setIsUserConfirm] = useState(false);

  // useEffect(() => {
  //   if (!value && wcsState?.owing_sum_min) {
  //     setValue(wcsState.owing_sum_min)
  //   }
  // }, [value, wcsState])

  const handleSliderChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleInputChange = (event: any) => {
    // console.info(`${event.target.name} name -`, event.target.value)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setValue(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const submitPayment = async () => {
    setIsUserConfirm(true);
    const {
      wog_id,
      pride_id,
      phone,
      token,
      callback_url,
      owing_sum,
      owing_order_id,
    } = wcsState;
    const url = `${config.url.API_URL}/installment/wog-repayment-transport?token=${token}`;

    const requestBody = {
      wog_id,
      pride_id,
      phone,
      callback_url,
      owing_order_id,
      amount: `${value}`,
      fullAmount: `${owing_sum}`,
      description: 'Repayment for client',
    };

    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        origin: '*actinia.tech',
        'Content-Type': 'application/json',
        Cookie: 'deviceFP=15914eaa3bd22928276cd5ddf4a7f1b13901758a',
      },
      redirect: 'follow', // manual, *follow, error
      // referrerPolicy: "no-referrer", // no-referrer, *client
      body: JSON.stringify(requestBody),
    });

    const response = await res.json();

    if (response.data?.error) {
      setIsUserConfirm(false);
      history.push(
        `${ROUTES.wog.installment.repayment.CANCELED}/${Date.now()}`,
        {
          token,
        },
      );
    } else {
      setIsUserConfirm(false);
      window.location.href = response.data.url;
    }
  };

  const handleBlur = () => {
    // if (wcsState && value < (wcsState.owing_sum_min as number)) {
    //   setValue(wcsState.owing_sum_min as number);
    // } else if (wcsState.owing_sum && value > wcsState.owing_sum) {
    //   setValue(wcsState.owing_sum);
    // }
  };

  const validation = useCallback(
    // eslint-disable-next-line no-shadow
    (value) => value >= wcsState?.owing_sum_min && value <= wcsState?.owing_sum,
    [wcsState],
  );

  const isValid = useMemo(
    () => value >= wcsState?.owing_sum_min && value <= wcsState?.owing_sum,
    [value, wcsState?.owing_sum, wcsState?.owing_sum_min],
  );

  return (
    <MainPageLayout>
      {isUserConfirm && (
        <div className={styles.overlay}>
          <LoaderComponent />
        </div>
      )}
      <div className={styles.payment}>
        <Link
          to={`${ROUTES.wog.installment.repayment.HELP}/${Date.now()}`}
          className={styles.payment__help}
        >
          <Help />
        </Link>
        <div className={styles.payment__icon}>
          <Icon />
        </div>
        <div className={styles.payment__header}>
          Погашення заборгованості
          {' '}
          {allName}
        </div>
        <div className={styles.payment__description}>
          <div className={styles.payment__item}>
            <span className={styles.payment__title}>Використано:</span>
            <span className={styles.payment__wasted}>
              {wcsState.owing_sum}
              {' '}
              <Currency className={styles.payment__currency} />
            </span>
          </div>
          <div className={styles.payment__item}>
            <span className={styles.payment__title}>ОПЛАТИТИ:</span>
            <div className={styles.payment__input}>
              <WCSTextInput
                labelName=""
                error={!isValid}
                onChange={handleInputChange}
                // onBlur={onBlurAction}
                // onBlur={handleBlur}
                classes={{
                  root: styles.text__input,
                }}
                value={(+value.toFixed(2)).toString()}
                // value={value}
                type="number"
                validationSchema={validation}
                inputProps={{
                  id: 'wogInstallmentPayment',
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.payment__range}>
          <div className={styles.payment__range__input}>
            <InputRange
              min={+wcsState.owing_sum_min.toFixed(2) as number}
              max={+wcsState.owing_sum.toFixed(2) as number}
              step={
                (+wcsState.owing_sum_min.toFixed(2) as number) < 1 ? 0.1 : 1
              }
              value={value}
              onChange={handleSliderChange}
            />
            <div className={styles.payment__range__description}>
              <span>
                {wcsState.owing_sum_min.toFixed(2)}
                {' '}
                грн
              </span>
              <span>
                {wcsState.owing_sum.toFixed(2)}
                {' '}
                грн
              </span>
            </div>
          </div>
        </div>
        <div className={styles.payment__button}>
          <WCSButton
            type="button"
            buttonText="Оплатити"
            handleClick={submitPayment}
            isDisabled={
              value < wcsState.owing_sum_min || value > wcsState.owing_sum
            }
          />
        </div>
      </div>
    </MainPageLayout>
  );
};

export default WCSPayment;
