import { Link, useLocation } from 'react-router-dom';
import React, { ReactElement } from 'react';
import MainPageLayout from '../../../../components/MainPageLayout/MainPageLayout';
import { ReactComponent as Icon } from '../../../../assets/images/wcs/payment.svg';
import styles from '../WCSPayment/WCSPayment.module.scss';
import { IWCSAttempt } from '../../../../utils/routesProvider';
import { ReactComponent as Currency } from '../../../../assets/images/wcs/ua.svg';
import { ROUTES } from '../../../../core/_consts/routes';
import { ReactComponent as Help } from '../../../../assets/images/wcs/help-icon.svg';

const WCSPaymentAttempt = (): ReactElement => {
  const location = useLocation();
  const wcsState = (location.state as any).routingParameters
    ?? (JSON.parse(
      sessionStorage.getItem('routingParameters') as string,
    ) as IWCSAttempt);
  const fullName = `${wcsState.fname} ${wcsState.lname}`;
  const date = wcsState.owing_compensated_start.split(' ')[0].split('-');
  const [year, month, day] = date;
  const startDate = `00:00 ${[day, month, year].join('.')}`;

  return (
    <MainPageLayout>
      <Link
        to={`${ROUTES.wog.installment.repayment.HELP}/${Date.now()}`}
        className={styles.payment__help}
      >
        <Help />
      </Link>
      {wcsState && (
        <div className={styles.payment}>
          <div className={styles.payment__icon}>
            <Icon />
          </div>
          <div className={styles.payment__header}>
            Погашення заборгованості
            {' '}
            {fullName}
          </div>
          <div className={styles.payment__description}>
            <div className={styles.payment__item}>
              <span className={styles.payment__title}>Використано:</span>
              <span className={styles.payment__wasted}>
                {wcsState.owing_sum}
                {' '}
                <Currency className={styles.payment__currency} />
              </span>
            </div>
            <div className={styles.payment__declined}>
              <div>Погашення заборгованості стане можливим починаючи </div>
              <div>
                з
                {' '}
                {startDate}
              </div>
            </div>
          </div>
        </div>
      )}
    </MainPageLayout>
  );
};

export default WCSPaymentAttempt;
