/* eslint-disable react/destructuring-assignment */
import React, { ReactElement } from 'react';
import Logo from '../../../assets/images/leasing/logo.svg';
// import Close from "../../assets/images/close.svg";

import styles from './Header.module.scss';

const HeaderLeasing = (): ReactElement => (
  <div className={styles.header}>
    <div className={styles.header__inner}>
      <div className={styles.header__content}>
        <a href="https://actinia.com.ua/" target="_blank" rel="noreferrer">
          <img src={Logo} alt="logo" />
        </a>
        {/* <img src={Close} alt="close-icon" className={styles.header__close} /> */}
      </div>
    </div>
  </div>
);

export default HeaderLeasing;
