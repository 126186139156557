import React from 'react';
// import Header from "../../components/Headers/Header";
import Error from '../../../../assets/images/leasing/error.svg';
import MainPageLayout from '../../../../components/MainPageLayout/MainPageLayout';
// import ContinueButton from "../../components/ContinueButton";

import styles from './Declined.module.scss';

const LeasingDeclined = (): JSX.Element => (
  <MainPageLayout>
    <div className={styles.content}>
      <div className={styles.content__declined}>
        <div className={styles.content__title}>
          Нажаль, не вдалося зарахувати ваш платіж, спробуйте ще раз
        </div>
        <img
          src={Error}
          alt="declined"
          className={styles.content__image}
        />
        {/* <div className={styles.content__button}>
            <ContinueButton buttonText="Повернутися" type="button" />
          </div> */}
      </div>
    </div>
  </MainPageLayout>
);

export default LeasingDeclined;
