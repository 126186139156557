import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import WCSButton from '../../../../components/Buttons/WCSButton/WCSButton';
import LoaderComponent from '../../../../components/Loader/Loader';
import MainPageLayout from '../../../../components/MainPageLayout/MainPageLayout';
import WCSContent from '../../../../components/WCSContent/WCSContent';
import WCSOffer from '../../../../components/WCSOffer/WCSOffer';
import { config } from '../../../../core/_consts/config';
import { ROUTES } from '../../../../core/_consts/routes';
import styles from './WCSNoverification.module.scss';
import { ReactComponent as Help } from '../../../../assets/images/wcs/help-icon.svg';
import WCSActivationInfo from '../WCSActivationInfo/WCSActivationInfo';

const WCSNoVerification = () => {
  const history = useHistory();
  const location = useLocation();
  const storage = localStorage;
  const [isChangePage, setIsChangePage] = useState(false);

  const [wcsState, setWcsState] = useState(
    (location.state as any).routingParameters
      ?? JSON.parse(sessionStorage.getItem('routingParameters') as string),
  );
  const [isUserConfirm, setIsUserConfirm] = useState(false);
  const [checked, setChecked] = useState(true);

  const changCheckbox = (value: boolean) => {
    setChecked(value);
  };

  const handleClick = () => {
    setIsChangePage(true);
    storage.setItem('isShowActivationInfo', 'true');
  };

  const includesWogTips = async () => {
    setIsUserConfirm(true);
    const url = `${config.url.API_URL}/wcs/installment/wog-noverify`;
    const { wog_id, pride_id, phone } = wcsState;
    const requestBody = {
      wog_id,
      pride_id,
      phone,
    };

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        origin: '*actinia.tech',
        'Content-Type': 'application/json',
        Cookie: 'deviceFP=15914eaa3bd22928276cd5ddf4a7f1b13901758a',
      },
      redirect: 'follow', // manual, *follow, error
      // referrerPolicy: "no-referrer", // no-referrer, *client
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();

    if (data.data.success) {
      setIsUserConfirm(false);
      history.push(
        `${
          ROUTES.wog.installment.verification.ACCEPTED_WITHOUT_VERIFICATION
        }/${Date.now()}`,
        {
          routingParameters: {
            activation_type_id: 1,
            token: wcsState.token,
          },
        },
      );
    } else {
      setIsUserConfirm(false);
      history.push(
        `${ROUTES.wog.installment.verification.DECLINED}/${Date.now()}`,
      );
    }
  };

  return (
    <>
      {!storage.getItem('isShowActivationInfo') && (
        <WCSActivationInfo changePage={handleClick} />
      )}
      {wcsState && !!storage.getItem('isShowActivationInfo') && (
        <MainPageLayout>
          <Link
            to={`${ROUTES.wog.installment.repayment.HELP}/${Date.now()}`}
            className={styles.help}
          >
            <Help />
          </Link>
          {isUserConfirm && (
            <div className={styles.overlay}>
              <LoaderComponent />
            </div>
          )}
          <div className={styles.container}>
            <WCSContent limit={wcsState.limit_max} />
            <WCSOffer checked={checked} changeChecked={changCheckbox} />
          </div>
          <div className={styles.button}>
            <WCSButton
              type="button"
              buttonText="активувати"
              isDisabled={!checked}
              handleClick={includesWogTips}
            />
          </div>
        </MainPageLayout>
      )}
    </>
  );
};

export default WCSNoVerification;
