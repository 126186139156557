import React, { ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// import {ReactComponent as Close} from '../../../assets/images/wcs/clear.svg'
import { ReactComponent as Declined } from '../../../../assets/images/wcs/wcs-declined.svg';
import WCSButton from '../../../../components/Buttons/WCSButton/WCSButton';
import { ROUTES } from '../../../../core/_consts/routes';

import styles from './WCSDeclined.module.scss';

const WCSDeclined = (): ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const token = (location.state as any)?.token;

  const handleClick = () => {
    history.push({
      pathname: `${ROUTES.wog.installment.verification.HOME}/${Date.now()}`,
      search: `?token=${token}`,
    });
  };

  return (
    <div className={styles.declined}>
      <div className={styles.declined__close}>{/* <Close/> */}</div>
      <div className={styles.declined__description}>
        <p>Помилка відправки даних.</p>
      </div>
      <div className={styles.declined__image}>
        <Declined />
      </div>
      <div className={styles.declined__button}>
        <WCSButton
          type="button"
          buttonText="Спробувати ще раз"
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

export default WCSDeclined;
