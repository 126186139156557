import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Help } from '../../../../assets/images/wcs/help-icon.svg';
import { ROUTES } from '../../../../core/_consts/routes';
import MainPageLayout from '../../../../components/MainPageLayout/MainPageLayout';
import { ReactComponent as Check } from '../../../../assets/images/wcs/check-mark.svg';
import WCSButton from '../../../../components/Buttons/WCSButton/WCSButton';

import styles from '../WCSPageInfo/WCSInfo.module.scss';

interface WCSActivationProps {
  changePage: () => void;
}

const WCSActivationInfo: FC<WCSActivationProps> = ({
  changePage,
}: WCSActivationProps) => (
  <MainPageLayout>
    <div className={styles.info}>
      <Link
        to={`${ROUTES.wog.installment.repayment.HELP}/${Date.now()}`}
        className={styles.info__help}
      >
        <Help />
      </Link>
      <div className={styles.info__header}>
        Інформація про послугу “Заправляй зараз, а сплачуй потім”
      </div>
      <ul className={styles.info__list}>
        <li className={styles.info__item}>
          <span className={styles.info__icon}>
            <Check />
          </span>
          <span className={styles.info__description}>
            Послуга дозволяє придбати пальне та сплатити
            витрачені кошти впродовж
            {' '}
            <span>14 днів</span>
            .
          </span>
        </li>
        <li className={styles.info__item}>
          <span className={styles.info__icon}>
            <Check />
          </span>
          <span className={styles.info__description}>
            Після активації послуги, Ви можете скористатися нею через сервіс
            {' '}
            <span>WOG PAY</span>
            {' '}
            пальне в розділі спосіб оплати.
          </span>
        </li>
        <li className={styles.info__item}>
          <span className={styles.info__icon}>
            <Check />
          </span>
          <span className={styles.info__description}>
            Все просто, заправляйся зараз- плати потім!
          </span>
        </li>
        <li className={styles.info__item}>
          <span className={styles.info__icon}>
            <Check />
          </span>
          <span className={styles.info__description}>
            Зверніть увагу, при купівлі пального за цією послугою, Вам не буде
            нарахована винагорода по програмі лояльності
            {' '}
            <span>ПРАЙД</span>
            .
          </span>
        </li>
      </ul>
      <div className={styles.info__button}>
        <WCSButton
          buttonText="СКОРИСТАТИСЯ"
          type="button"
          handleClick={changePage}
        />
      </div>
    </div>
  </MainPageLayout>
);

export default WCSActivationInfo;
